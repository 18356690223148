<template>
  <div v-if="data">
    <Layout01
      :data="data"
      :slug="slug" />
  </div>
</template>

<script>
import engineApi from '@/plugins/engine-api.js'
import xhr from '@/mixins/Xhr.js'
import Layout01 from '@/components/layouts-aggregate/Layout01'

export default {
  components: {
    Layout01
  },
  mixins: [xhr],
  data: function () {
    return {
      slug: null,
      data: null
    }
  },
  created () {
    if (this.$route.params) {
      this.slug = this.$route.params.slug
      var vm = this
      engineApi.fetchHeadline(
        this.slug, 1,
        function (err, response) {
          if (err) {
            vm.handleXhrError(err)
          } else {
            if (typeof response === 'string') {
              vm.data = JSON.parse(response)
            } else if (typeof response === 'object') {
              vm.data = response
            } else {
              window.location = process.env.VUE_APP_404_PAGE
            }
          }
        }
      )
    }
  }
}
</script>
