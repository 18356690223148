<template>
  <div v-if="data">
    <Checkout
      v-if="checkout"
      :data="data"
      :order="order"
      :words="words"
      @toggle="toggleCheckout(false)"/>
    <Layout01
      v-else-if="data.campaign.layout === 'simple'"
      :data="data"
      :order="order"
      @checkout="toggleCheckout"/>
    <Layout02
      v-else-if="data.campaign.layout === 'detail'"
      :data="data"
      :order="order"
      @checkout="toggleCheckout"/>
    <Layout03
      v-else-if="data.campaign.layout === 'column'"
      :data="data"
      :order="order"
      @checkout="toggleCheckout"/>
  </div>
</template>

<script>
import engineApi from '@/plugins/engine-api.js'
import xhr from '@/mixins/Xhr.js'
import Checkout from '@/components/Checkout'
import Layout01 from '@/components/layouts/Layout01'
import Layout02 from '@/components/layouts/Layout02'
import Layout03 from '@/components/layouts/Layout03'

export default {
  components: {
    Layout01,
    Layout02,
    Layout03,
    Checkout
  },
  mixins: [xhr],
  data: function () {
    return {
      slug: null,
      data: null,
      checkout: false,
      order: {}
    }
  },
  computed: {
    words () {
      if (this.data.campaign) {
        if (this.data.campaign.words) return this.data.campaign.words.variables
        return {}
      } else {
        return {}
      }
    }
  },
  created () {
    if (this.$route.params) {
      this.slug = this.$route.params.slug
      if (this.slug === 'edit-simple') {
        this.data = {
          editMode: true,
          campaign: {
            layout: 'simple'
          }
        }
      } else if (this.slug === 'edit-detail') {
        this.data = {
          editMode: true,
          campaign: {
            layout: 'detail'
          }
        }
      } else if (this.slug === 'edit-column') {
        this.data = {
          editMode: true,
          campaign: {
            layout: 'column'
          }
        }
      } else {
        // pass the skipHit flag if this was passed through via the URL
        var skiphit = false
        if (this.$route.query.skiphit) skiphit = true
        // request the landing page
        var vm = this
        engineApi.fetchCampaign(
          this.slug, skiphit,
          function (err, response) {
            if (err) {
              vm.handleXhrError(err)
            } else {
              if (typeof response === 'string') {
                vm.data = JSON.parse(response)
                if (vm.data.campaign.questions) {
                  // fix for design, hide all required stars and field errors
                  vm.data.campaign.questions.forEach(function (question) {
                    question.hideErrors = true
                    question.hideRequired = false
                    // fix group field questions
                    if (question.questions) {
                      if (question.questions.length > 0) {
                        question.questions.forEach(function (subquestion) {
                          subquestion.hideErrors = true
                          subquestion.hideRequired = false
                        })
                      }
                    }
                  })
                }
                // add some campaign stuff to the order JSON
                vm.order.campaignId = vm.data.campaign.id
              } else {
                window.location = process.env.VUE_APP_404_PAGE
              }
            }
          }
        )
      }
    }
  },
  methods: {
    toggleCheckout (value) {
      if (value) {
        this.checkout = true
      } else {
        this.checkout = false
        window.location.reload() // refresh the landing page (qtys)
      }
    }
  }
}
</script>
