<template>
  <div id="paypal-button-container"/>
</template>

<script>
import VueScript2 from 'vue-script2'
import analytics from '@/mixins/Analytics.js'

export default {
  mixins: [analytics],
  props: {
    paypalAmount: {
      type: Number,
      required: true
    },
    paypalSandbox: {
      type: String,
      required: false
    },
    paypalProduction: {
      type: String,
      required: false
    },
    paypalCurrency: {
      type: String,
      required: true
    },
    paypalMode: {
      type: String,
      required: true
    },
    customer: {
      type: Object,
      required: true
    },
    data: {
      type: Object,
      required: true
    },
    order: {
      type: Object,
      required: true
    }
  },
  data: function () {
    return {
      formValidChange: function () {}
    }
  },
  computed: {
    isFormValid () {
      return !Object.keys(this.fields).some(key => this.fields[key].invalid)
    }
  },
  watch: {
    isFormValid: function (val) {
      this.formValidChange()
    }
  },
  mounted () {
    // Load Paypal
    var vm = this
    VueScript2.load('https://www.paypalobjects.com/api/checkout.min.js').then(function () {
      vm.renderPaypal()
    })
  },
  methods: {
    renderPaypal () {
      var vm = this
      window.paypal.Button.render({
        env: vm.paypalMode,
        style: {
          label: 'checkout',
          size: 'responsive',
          shape: 'rect',
          color: 'gold',
          tagline: false
        },
        client: {
          sandbox: vm.paypalSandbox,
          production: vm.paypalProduction
        },
        payment: function (data, actions) {
          return actions.payment.create({
            payment: {
              transactions: [
                {
                  description: 'Order',
                  amount: {
                    total: vm.paypalAmount,
                    currency: vm.paypalCurrency
                  },
                  item_list: {
                    shipping_address: {
                      recipient_name: vm.customer.name,
                      line1: vm.customer.street,
                      line2: vm.customer.street2,
                      city: vm.customer.city,
                      country_code: vm.customer.country,
                      postal_code: vm.customer.postal,
                      phone: vm.customer.phone,
                      state: vm.customer.state
                    }
                  }
                }
              ]
            },
            experience: {
              input_fields: {
                no_shipping: 1
              }
            }
          })
        },
        validate: function (actions) {
          vm.toggleButton(actions)
          vm.formValidChange = function () {
            vm.toggleButton(actions)
          }
        },
        onClick: function () {
          vm.toggleValidationMessage()
        },
        onAuthorize: function (data, actions) {
          return actions.payment.execute()
            .then(function () {
              vm.order.payment = data
              vm.order.payment.engineType = 'paypal'
              vm.$emit('confirm', true)
            })
        },
        onError: function (err) {
          var paypalError = null
          if (err.message) {
            err = err.message.match(/{([\s\S]*?)^}/gm)
            if (err.length) {
              err = err[0]
              err = JSON.parse(err)
              err = err['name']
            }
          }
          switch (err) {
            case 'CREDIT_CARD_CVV_CHECK_FAILED':
              paypalError = 'PayPal responded your credit card and CVV did not match.'
              break
            case 'CREDIT_CARD_REFUSED':
              paypalError = 'PayPal responded your credit card was refused.'
              break
            case 'CREDIT_PAYMENT_NOT_ALLOWED':
              paypalError = 'PayPal responded your payment was not allowed.'
              break
            case 'INVALID_CITY_STATE_ZIP':
              paypalError = 'It looks like there is an issue with your city, state, and postal code combination.'
              break
            case 'MAX_NUMBER_OF_PAYMENT_ATTEMPTS_EXCEEDED':
              paypalError = 'You have exceeded the number of PayPal payment attempts. Please wait a moment before trying again.'
              break
            case 'SHIPPING_ADDRESS_INVALID':
              paypalError = 'It looks like there is an issue with your address.'
              break
            case 'VALIDATION_ERROR':
              paypalError = 'It looks like there is an issue with your address.'
              break
            default:
              paypalError = null
          }
          vm.$emit('error', paypalError)
        }
      }, '#paypal-button-container')
    },
    toggleButton (actions) {
      return this.isFormValid ? actions.enable() : actions.disable()
    },
    toggleValidationMessage () {
      if (this.isFormValid) {
        // YOU CAN REMOVE ERROR MESSAGES HERE !!
        if (process.env.NODE_ENV !== 'development') this.analyticsStartPayment('paypal')
      } else {
        this.$emit('showErrors')
        this.$validator.validateAll().then(result => {})
      }
    }
  },
  inject: {
    $validator: '$validator'
  }
}
</script>
