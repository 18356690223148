<template>
  <div v-if="inputs">
    <div
      v-for="(field, index) in inputs"
      :key="'f' + index"
      :class="{ 'oto-selected-question': !editMode || (selected === index || selected < 0), 'oto-editable-no-hover': editMode }"
      class="field oto-not-selected-question"
      @click="onclick(index)">
      <TextInput
        v-if="field.type === 'text-input'"
        :props="field"
        :label="label"
        :disabled="disabled"
        v-model="field.model"/>
      <TextArea
        v-else-if="field.type === 'text-area'"
        :props="field"
        :label="label"
        :disabled="disabled"
        v-model="field.model"/>
      <div
        v-else-if="field.type === 'grouped-field'"
        class="grouped-field">
        <label
          v-if="field.label"
          :style="label.style"
          :class="label.class"
          class="label">
          <span>{{ field.label }}</span>
        </label>
        <div
          v-for="(gField, gIndex) in field.questions"
          :key="'g' + gIndex"
          :class="{ 'oto-selected-question': !editMode || (selected === index || selected < 0) }"
          class="field">
          <TextInput
            v-if="gField.type === 'text-input'"
            :props="gField"
            :label="label"
            :disabled="disabled"
            v-model="gField.model"/>
          <TextArea
            v-else-if="gField.type === 'text-area'"
            :props="gField"
            :label="label"
            :disabled="disabled"
            v-model="gField.model"/>
          <StateSelect
            v-else-if="gField.type === 'state-select'"
            :props="gField"
            :label="label"
            :disabled="disabled"
            v-model="gField.model"/>
        </div>
      </div>
      <div
        v-else-if="field.type === 'option-group'"
        class="option-group-field">
        <Select
          v-if="field['option-type'] === 'select'"
          :props="field"
          :label="label"
          :disabled="disabled"
          v-model="field.model"/>
        <Lists
          v-else-if="field['option-type'] === 'list'"
          :props="field"
          :label="label"
          :disabled="disabled"
          v-model="field.model"/>
      </div>
    </div>
    <div
      v-if="addOpt"
      class="field optbox-field">
      <input
        id="optbox"
        v-model="optStatus"
        name="optbox"
        class="is-checkradio"
        type="checkbox">
      <label
        :class="label.class"
        :style="label.style"
        for="optbox"
        class="checkbox-label">
        Send me your latest news and deals via email
      </label>
    </div>
  </div>
</template>

<script>
import Lists from '@/components/elements/Lists'
import TextInput from '@/components/elements/TextInput'
import TextArea from '@/components/elements/TextArea'
import Select from '@/components/elements/Select'
import StateSelect from '@/components/elements/StateSelect'

export default {
  components: {
    Lists,
    TextArea,
    TextInput,
    Select,
    StateSelect
  },
  props: {
    editMode: {
      type: Boolean,
      default: false
    },
    value: {
      type: Number,
      default: null
    },
    questions: {
      type: Array,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    addOpt: {
      type: Boolean,
      default: false
    },
    label: {
      type: Object,
      default: () => ({
        style: null,
        class: null
      })
    }
  },
  data: function () {
    return {
      selected: null,
      inputs: null,
      optStatus: null
    }
  },
  watch: {
    questions (val) {
      this.inputs = val
    },
    value (val) {
      this.selected = val
    },
    optStatus (val) {
      this.$emit('opt', val)
    }
  },
  mounted () {
    this.selected = this.value
    this.inputs = this.questions
    this.optStatus = true // default set here
  },
  methods: {
    onclick (index) {
      this.$emit('input', index)
      this.selected = index
    }
  }
}
</script>

<style scoped>
.oto-selected-question {
  opacity: 1 !important;
}
.oto-not-selected-question {
  opacity: 0.3;
}
.grouped-field > .label {
  font-size: 1.1em;
  padding-top: 15px;
}
.optbox-field {
  padding-top: 10px;
}
</style>
