<template>
  <div
    v-if="contentReady && account"
    :style="cssProps"
    class="page-wrapper oto-lp1">
    <!-- HEADER AND NAVBAR -->
    <div class="container">
      <nav class="navbar is-spaced">
        <div class="navbar-brand">
          <div class="navbar-item">
            <img
              v-if="account.logoWide"
              :src="account.logoWide"
              :alt="'Logo for ' + account.name">
          </div>
        </div>
        <div class="navbar-menu">
          <div class="navbar-start">
          </div>
          <div class="navbar-end">
            <div class="navbar-item">
              <Share :props="shareProps"/>
            </div>
          </div>
        </div>
      </nav>
    </div>
    <!-- IMAGE BACKGROUND, TITLE, DESCRIPTION, CALL TO ACTION -->
    <section
      :style="{ background: 'linear-gradient(rgba(' + theme.currentColors.primaryRGB + ', 0.65), rgba(' + theme.currentColors.primaryRGB + ', 0.80)), url(\'' + images[0] + '\') no-repeat center center', backgroundColor: 'rgb(12, 81, 185)' }"
      class="hero oto-image-overlay">
      <div class="hero-body">
        <div class="container">
          <div
            class="columns is-mobile is-centered has-text-centered"
            :style="{ color: '' + theme.currentColors.primaryFont + ''}">
            <div class="column is-three-fifths-tablet oto-landing-details">
              <div
                v-if="currentTitle"
                :class="{ 'oto-editable': editMode && numberOfPhrases('title') > 1 }"
                @click="changePhrase('title')">
                <div
                  v-if="editMode && numberOfPhrases('title') > 1"
                  class="oto-editable-button">
                  <a class="tag is-light">
                    <span class="icon is-small has-text-white">
                      <i class="fas fa-sync-alt"/>
                    </span>
                  </a>
                </div>
                <h1
                  :class="theme.currentFont.class"
                  class="title is-size-1"
                  :style="{ color: '' + theme.currentColors.primaryFont + ''}">
                  {{ currentTitle }}
                </h1>
              </div>
              <div
                v-if="currentHeading"
                :class="{ 'oto-editable': editMode && numberOfPhrases('heading') > 1 }"
                @click="changePhrase('heading')">
                <div
                  v-if="editMode && numberOfPhrases('heading') > 1"
                  class="oto-editable-button">
                  <a class="tag is-light">
                    <span class="icon is-small has-text-white">
                      <i class="fas fa-sync-alt"/>
                    </span>
                  </a>
                </div>
                <div
                  class="is-uppercase is-size-5 has-text-weight-semibold oto-uppercase-letter-spacing"
                  :style="{ color: '' + theme.currentColors.primaryFont + ''}">
                  {{ currentHeading }}
                </div>
              </div>
              <div
                v-if="currentDescription"
                :class="{ 'oto-editable': editMode && numberOfPhrases('description') > 1 }"
                @click="changePhrase('description')">
                <div
                  v-if="editMode && numberOfPhrases('description') > 1"
                  class="oto-editable-button">
                  <a class="tag is-light">
                    <span class="icon is-small has-text-white">
                      <i class="fas fa-sync-alt"/>
                    </span>
                  </a>
                </div>
                <div
                  class="has-text-weight-semibold"
                  :style="{ color: '' + theme.currentColors.primaryFont + ''}">
                  {{ currentDescription }}
                </div>
              </div>
              <div
                v-if="showEndCountdown"
                class="oto-lp1 oto-flip-ends-holder">
                <div class="oto-flip-header">
                  <span v-if="tickets">Act Fast! Registration Ends In:</span>
                  <span v-else-if="product">Act Fast! Sale Ends In:</span>
                </div>
                <flip-countdown :deadline="flipEndDate"/>
              </div>
              <div
                v-if="showActionButton"
                :class="{ 'oto-editable': editMode && numberOfPhrases('action') > 1 }"
                @click="changePhrase('action')">
                <div
                  v-if="editMode && numberOfPhrases('action') > 1"
                  class="oto-editable-button">
                  <a class="tag is-light">
                    <span class="icon is-small has-text-white">
                      <i class="fas fa-sync-alt"/>
                    </span>
                  </a>
                </div>
                <a
                  class="button is-marginless is-large is-size-6 is-uppercase has-text-weight-bold oto-uppercase-letter-spacing oto-trans-border"
                  href="#action-area"
                  :style="{ backgroundColor: '' + theme.currentColors.secondary + '', color: '' + theme.currentColors.secondaryFont + ''}">
                  {{ currentAction }}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- HTML AREA -->
    <div class="section">
      <div class="container">
        <div class="columns is-centered">
          <div class="column is-three-fifths o-content-area">
            <!-- CUSTOM RICH CONTENT -->
            <div
              v-if="currentContent"
              :class="{ 'oto-editable-no-hover': editMode }">
              <div
                v-if="editMode"
                class="oto-editable-html-button"
                @click="toggleRichEdit()">
                <a class="tag has-addons">
                  <span class="icon is-small has-text-white">
                      <i class="fas fa-pen"/>
                    </span>
                  <span class="has-text-white">Edit Content</span>
                </a>
              </div>
              <Editor
                v-if="editMode && richEdit"
                v-model="richContent"
                :api-key="tinymceKey"
                :init="tinyoptions"/>
              <div
                v-else-if="editMode"
                @click="toggleRichEdit()">
                <div class="content">
                  <p v-html="currentContent"></p>
                </div>
              </div>
              <div
                v-else
                class="content">
                <p v-html="currentContent"></p>
              </div>
            </div>
            <!-- AUTOGEN DATE, LOCATION, TICKET TABLE -->
            <div class="columns">
              <div
                v-if="date"
                class="column o-date-holder">
                <p>Date</p>
                <p>{{ date }}</p>
                <a
                  v-if="iCalUrl"
                  :style="{color: theme.currentColors.secondary}"
                  class="has-text-weight-bold"
                  :href="iCalUrl">Add to Calendar</a>
              </div>
              <div
                v-if="action === 'event' && eventLocationHTML"
                class="column o-location-holder">
                <p>Location</p>
                <span
                  v-html="eventLocationHTML"
                  class="o-location"/>
                <div
                  v-if="eventDirectionsURL"
                  class="o-directions">
                  <a
                    :href="eventDirectionsURL"
                    :style="{color: theme.currentColors.secondary}"
                    class="has-text-weight-bold no-print"
                    target="_blank">Get Directions</a>
                </div>
              </div>
            </div>
            <!-- AUTOGEN TICKET TABLE -->
            <div
              v-if="ticketPriceTable"
              class="o-ticket-price-table-holder"
              v-html="ticketPriceTable"/>
          </div>
        </div>
      </div>
    </div>
    <!-- BUTTON AREA -->
    <div
      v-if="embedButton"
      class="section oto-section-button-padding">
      <div class="container">
        <div class="columns is-centered">
          <div
            :style="buttonAlign"
            class="column is-three-fifths o-content-area">
            <a
              class="button is-marginless is-large is-size-6 is-uppercase has-text-weight-bold oto-uppercase-letter-spacing oto-trans-border is-fullwidth-mobile"
              target="_blank"
              :href="embedButton.url"
              :style="{ backgroundColor: '' + theme.currentColors.secondary + '', color: '' + theme.currentColors.secondaryFont + ''}">
              {{ embedButton.text }}
            </a>
          </div>
        </div>
      </div>
    </div>
    <!-- ACTION AREA -->
    <a id="action-area"/>
    <section
      v-if="showActionArea"
      class="section"
      :style="{ backgroundColor: '' + theme.currentColors.primary + ''}">
      <div class="container">
        <div class="columns is-centered">
          <div class="column is-three-fifths">
            <h2
              :class="theme.currentFont.class"
              class="title is-size-2-desktop has-text-centered oto-title-margin-bottom"
              style="color: #ffffff;">
              {{ currentAction }}
            </h2>
            <h3
              class="title is-size-5 has-text-centered is-uppercase has-text-weight-bold oto-uppercase-letter-spacing"
              style="color: #ffffff;">
              {{ currentTitle }}
            </h3>
          </div>
        </div>
        <div class="columns is-centered">
          <!-- TICKETS / PRODUCT / FORM ACTION -->
          <div class="column is-half-desktop is-two-thirds-tablet">
            <div>
              <div id="messageArea"/>
              <div v-if="confirmMessage">
                <div class="notification is-primary">
                  {{ confirmMessage }}
                </div>
              </div>
              <div v-else>
                <div v-if="errorMessage">
                  <div class="notification is-danger">
                    {{ errorMessage }}
                  </div>
                </div>
                <div
                  v-if="!isPurchaseOpen && timeToPurchase && flipStartDate"
                  class="oto-lp1 oto-flip-starts-holder">
                  <div class="oto-flip-header">
                    <span v-if="tickets">Registration Starts In:</span>
                    <span v-else-if="product">Sale Starts In:</span>
                  </div>
                  <flip-countdown :deadline="flipStartDate"/>
                </div>
                <div
                  v-else-if="!isPurchaseOpen && qtyLeft <= 0 && qtyLeft !== null"
                  class="font-opensans title is-size-5 has-text-centered has-text-weight-bold"
                  style="color: red;">
                  <span v-if="tickets">We're sorry but this event is sold out.</span>
                  <span v-else-if="product">We're sorry but this item is sold out.</span>
                </div>
                <div
                  v-else-if="!isPurchaseOpen"
                  class="font-opensans title is-size-5 has-text-centered has-text-weight-bold"
                  style="color: red;">
                  <span v-if="tickets">We're sorry but registration for this event is now closed.</span>
                  <span v-else-if="product">We're sorry but this sale is no longer available.</span>
                </div>
                <form
                  v-else
                  @submit.prevent="submitForm">
                  <div v-if="subtype === 'paid'">
                    <!-- TICKET REGISTRATION -->
                    <div
                      v-if="availableTickets"
                      class="oto-event-holder">
                      <RenderRegistration
                        :editMode="editMode"
                        :registration="registration"
                        :tickets="availableTickets"
                        :ticketAddons="ticketAddons"
                        :questions="questions"
                        :theme="theme"
                        :order="order"
                        :quantities="quantities"
                        :currency="currencySettings"
                        v-model="selectedQuestion"
                        @input="postSelectedQuestion()"
                      />
                    </div>
                    <!-- PRODUCT PURCHASE -->
                    <div
                      v-else-if="product"
                      class="oto-product-holder">
                      <RenderProduct
                        :editMode="editMode"
                        :product="product"
                        :productOptions="productOptions"
                        :questions="questions"
                        :theme="theme"
                        :order="order"
                        :quantities="quantities"
                        :currency="currencySettings"
                        v-model="selectedQuestion"
                        @input="postSelectedQuestion()"
                      />
                    </div>
                  </div>
                  <!-- FORM -->
                  <div
                    v-else-if="questions"
                    class="oto-form-holder">
                    <RenderForm
                      v-model="selectedQuestion"
                      :editMode="editMode"
                      :questions="questions"
                      :label="{ style: { color: theme.currentColors.primaryFont } }"
                      :addOpt="useOpt"
                      @opt="optStatusChanged"
                      @input="postSelectedQuestion()"/>
                  </div>
                  <button
                    :disabled="isLoading || editMode"
                    :class="buttonClass"
                    class="button is-large is-size-6 is-fullwidth is-uppercase has-text-weight-bold oto-uppercase-letter-spacing oto-trans-border"
                    :style="{ backgroundColor: '' + theme.currentColors.secondary + '', color: '' + theme.currentColors.secondaryFont + ''}">
                    <span v-if="subtype === 'paid'">Checkout</span>
                    <span v-else>{{ currentAction }}</span>
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- IFRAME AREA -->
    <div v-if="embedIFrame">
      <div :style="iframeHolderStyle">
        <iframe
          :src="embedIFrame.url"
          :style="iframeStyle"/>
      </div>
    </div>
    <!-- FOOTER AREA -->
    <footer>
      <div class="section">
        <div class="container">
          <div class="columns">
            <div class="column">
              <div class="columns">
                <div
                  v-if="account"
                  class="column">
                  <img
                    v-if="account.logoWide"
                    :src="account.logoWide"
                    class="oto-footer-logo"
                    :alt="'Logo for ' + account.name">
                </div>
              </div>
              <div class="columns">
                <div
                  v-if="accountLocationHTML"
                  class="column">
                  <p class="has-text-weight-bold has-text-black">Location</p>
                  <p v-html="accountLocationHTML" />
                </div>
              </div>
              <div class="columns">
                <div
                  v-if="account"
                  class="column">
                  <p
                    v-if="account.phone"
                    class="has-text-weight-bold has-text-black">Phone</p>
                  <p v-if="account.phone">{{ account.phone }}</p>
                </div>
              </div>
            </div>
            <div
              v-if="googleMaps"
              class="column">
              <iframe
                title="Google Map"
                height="320"
                class="oto-map-height-fix"
                :src="googleMaps"
                allowfullscreen>
              </iframe>
            </div>
          </div>
        </div>
      </div>
      <div class="section has-background-white-ter">
        <div class="container">
          <div class="has-text-centered">Powered by
            <a
              :style="{ color: theme.currentColors.secondary }"
              href="https://campaignpilot.com" target="_blank">
              CampaignPilot
            </a>
          </div>
        </div>
      </div>
    </footer>
    <div
      v-if="editMode"
      class="oto-click-blocker"/>
  </div>
</template>

<script>
import layouts from '@/mixins/Layouts.js'
export default {
  mixins: [layouts],
  computed: {
    cssProps: function () {
      return {
        '--theme-color-alternate': this.theme.currentColors.alternate,
        '--theme-color-alternateFont': this.theme.currentColors.alternateFont,
        '--theme-color-primary': this.theme.currentColors.primary,
        '--theme-color-primaryFont': this.theme.currentColors.primaryFont,
        '--theme-color-primaryDarker': this.theme.currentColors.primaryDarker,
        '--theme-color-primaryDarkerFont': this.theme.currentColors.primaryDarkerFont,
        '--theme-color-secondary': this.theme.currentColors.secondary,
        '--theme-color-secondaryFont': this.theme.currentColors.secondaryFont,
        '--flip-font-color': this.theme.currentColors.primary,
        '--flip-top-font-color': this.theme.currentColors.primaryDarker,
        '--flip-border-color': '#fff',
        '--flip-bg-color': '#fff',
        '--flip-top-bg-color': '#F9F8F8'
      }
    }
  }
}
</script>
