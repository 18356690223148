<template>
  <div :style="{ color: theme.currentColors.primaryFont }">
    <div v-if="quantities.left">
      <p
        v-if="quantities.left < 10 && quantities.cap > 10"
        class="title is-size-5 has-text-centered has-text-weight-bold oto-action-subtitle"
        style="color: red;">
        Gone soon, only a few remaining!
      </p>
      <p
        v-else-if="quantities.left < 20 && quantities.cap > 20"
        class="title is-size-5 has-text-centered has-text-weight-bold oto-action-subtitle"
        style="color: red;">
        Going fast, limited quantity remaining!
      </p>
    </div>
    <div class="columns">
      <div class="column">
        <div class="oto-product-title">
          {{ product.name }}
        </div>
        <div
          v-if="product.price"
          :style="{ color: theme.currentColors.primaryFont }"
          class="oto-purchase-price">
          {{ currency.symbol }}{{ product.price }}
          <span
            v-if="product.fullPrice"
            :style="{ color: theme.currentColors.primaryFont }"
            class="oto-purchase-full-price">
            &nbsp;{{ currency.symbol }}{{ product.fullPrice }}
          </span>
          <div
            v-if="product.savings"
            :style="{ color: theme.currentColors.primaryFont }">
            Save {{ currency.symbol }}{{ product.savings }}!
          </div>
        </div>
        <figure
          v-if="product.image"
          class="image is-hidden-tablet oto-product-image">
          <img :src="product.image">
        </figure>
      </div>
      <div class="column">
        <figure
          v-if="product.image"
          class="image is-hidden-mobile is-pulled-right oto-product-image">
          <img :src="product.image">
        </figure>
      </div>
    </div>
    <div class="oto-product-form">
      <div
        v-if="!product.isGallus"
        class="field oto-qty-picker-holder">
        <label
          :style="{ color: theme.currentColors.primaryFont }"
          class="label">Order Quantity</label>
        <div class="field has-addons">
          <div class="control">
            <a
              :style="{ backgroundColor: '' + theme.currentColors.secondary + '', color: '' + theme.currentColors.secondaryFont + ''}"
              class="button is-primary oto-trans-border oto-quantity-button"
              @click="decreaseQty">-</a>
          </div>
          <div class="control">
            <input
              v-validate="{ required: true }"
              id="totalToBuy"
              ref="totalToBuy"
              v-model="totalToBuy"
              :class="{ 'is-danger': errors.has('totalToBuy') }"
              name="totalToBuy"
              class="input oto-qty-picker"
              type="text"
              placeholder=""
              readonly>
          </div>
          <div class="control">
            <a
              :style="{ backgroundColor: '' + theme.currentColors.secondary + '', color: '' + theme.currentColors.secondaryFont + ''}"
              class="button is-primary oto-trans-border oto-quantity-button"
              @click="increaseQty">+</a>
          </div>
        </div>
      </div>
      <div v-if="productOptions.length > 0">
        <div
          v-for="(option, index) in productOptions"
          :key="'o' + index"
          class="field">
          <div>
            <label
              :style="{ color: theme.currentColors.primaryFont }"
              class="label">
              <span>{{ option.name }}</span>
              <span
                v-if="option.required"
                class="icon has-text-danger is-small oto-required-field">
                <i class="fas fa-star-of-life oto-fa-mini"/>
              </span>
            </label>
          </div>
          <div v-if="option.type == '1' && option.options.length>0">
            <!-- SINGLE SELECT OPTION TYPE (1) -->
            <div class="select">
              <select @change="selectOption(option.name, $event.target.value)">
                <option :value="index"/>
                <option
                  v-for="(item, index2) in option.options"
                  :key="'i' + index2"
                  :value="index + ',' + index2">
                  {{ item.name }}
                  <span v-if="item.pAdjustAmount && !isGiftCard">
                    <span v-if="item.pAdjustType">(add</span>
                    <span v-else>(subtract</span>
                    {{ currency.symbol }}{{ item.pAdjustAmount }})
                  </span>
                </option>
              </select>
            </div>
          </div>
          <div v-else-if="option.type == '2' && option.options.length>0">
            <!-- MULTI SELECT OPTION TYPE (2) -->
            <div
              v-for="(item, index2) in option.options"
              :key="'i' + index2"
              class="field">
              <input
                :id="'o' + index + 'i' + index2"
                :name="'o' + index + 'i' + index2"
                class="is-checkradio is-dark"
                type="checkbox"
                @click="toggleOption(option.name, index, index2)">
              <label
                :for="'o' + index + 'i' + index2"
                :style="{ color: theme.currentColors.primaryFont }">
                {{ item.name }}
                <span v-if="item.pAdjustAmount && !isGiftCard">
                  <span v-if="item.pAdjustType">(add</span>
                  <span v-else>(subtract</span>
                  {{ currency.symbol }}{{ item.pAdjustAmount }})
                </span>
              </label>
            </div>
          </div>
        </div>
      </div>
      <div v-if="giftMode">
        <TextInput
          :props="giftRecipient.email"
          :label="{ style: { color: theme.currentColors.primaryFont } }"
          v-model="giftRecipient.email.model"/>
        <div class="oto-gift-mode-notice">
          <div class="icon has-text-grey">
            <i class="fas fa-exclamation-circle"/>
          </div>
          <div class="has-text-grey">
            Your virtual gift card will be delivered to this email address.
          </div>
        </div>
      </div>
      <div v-if="questions">
        <RenderForm
          v-model="selected"
          :editMode="editMode"
          :questions="questions"
          :label="{ style: { color: theme.currentColors.primaryFont } }"
          @input="postSelectedQuestion()"/>
      </div>
      <div
        v-if="textTotal"
        :style="{ color: theme.currentColors.primaryFont }"
        class="oto-total-order-price-heading">
        <hr class="has-background-grey-lighter">
        Your Total
        <span
          :style="{ color: theme.currentColors.primaryFont }"
          class="oto-total-order-price">
          {{ textTotal }} {{ currency.notation }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import RenderForm from '@/components/global/RenderForm'
import TextInput from '@/components/elements/TextInput'

export default {
  components: {
    RenderForm,
    TextInput
  },
  props: {
    editMode: {
      type: Boolean,
      default: false
    },
    value: {
      type: Number,
      default: null
    },
    order: {
      type: Object,
      required: true
    },
    product: {
      type: Object,
      required: true
    },
    productOptions: {
      type: Array,
      required: true
    },
    questions: {
      type: Array,
      required: false,
      default: function () { return [] }
    },
    theme: {
      type: Object,
      required: false,
      default: function () { return {} }
    },
    quantities: {
      type: Object,
      required: false,
      default: null
    },
    currency: {
      type: Object,
      required: false,
      default: function () { return {} }
    }
  },
  data () {
    return {
      totalToBuy: null,
      textTotal: null,
      selected: null,
      giftRecipient: {
        name: {
          label: 'Recipient Name',
          model: null,
          validate: { required: true },
          type: 'text-input',
          name: 'gift-card-recipient-name',
          ref: 'gift-card-recipient-name'
        },
        email: {
          label: 'Recipient Email',
          model: null,
          validate: { required: true },
          type: 'text-input',
          inputType: 'email',
          name: 'gift-card-recipient-email',
          ref: 'gift-card-recipient-email'
        }
      }
    }
  },
  watch: {
    totalToBuy (val) {
      this.order.totalToBuy = val
      this.calculateTotal()
    },
    value (val) {
      this.selected = val
    },
    'giftRecipient.name.model' (val) {
      this.order.giftRecipient.name = val
    },
    'giftRecipient.email.model' (val) {
      this.order.giftRecipient.email = val
    }
  },
  computed: {
    giftMode: function () {
      if (this.isGiftCard && this.product) {
        if (this.product.vgc) {
          return true // can add other logic here, but for now, gift mode is only enabled for virtual gift cards
        }
      }
      return false
    },
    isGiftCard: function () {
      if (this.product) {
        if (this.product.vgc) {
          return true
        } else {
          if (this.product.name) {
            if (typeof this.product.name === 'string') {
              if (this.product.name.toLowerCase().includes('gift card')) {
                return true
              }
            }
          }
          if (this.productOptions) {
            if (this.productOptions.length > 0) {
              this.productOptions.forEach(function (option) {
                if (option) {
                  if (option.name) {
                    if (typeof option.name === 'string') {
                      if (option.name.toLowerCase().includes('amount')) {
                        return true
                      }
                    }
                  }
                }
              })
            }
          }
        }
      }
      return false
    }
  },
  mounted () {
    this.selected = this.value
    this.resetOrder()
    this.calculateTotal()
  },
  methods: {
    resetOrder () {
      this.totalToBuy = 1
      this.order.total = null
      this.order.selectedOptions = {}
      this.order.product = this.product
      if (this.giftMode) {
        this.order.giftRecipient = {
          name: null,
          email: null
        }
      }
    },
    postSelectedQuestion () {
      this.$emit('input', this.selected)
    },
    toggleOption (field, group, item) {
      if (this.order.selectedOptions[group + ',' + item]) {
        delete this.order.selectedOptions[group + ',' + item]
      } else {
        this.order.selectedOptions[group + ',' + item] = {}
        this.order.selectedOptions[group + ',' + item].name = this.productOptions[group].options[item].name
        this.order.selectedOptions[group + ',' + item].field = field
        var price = null
        if (this.productOptions[group].options[item].pAdjustAmount) {
          price = this.productOptions[group].options[item].pAdjustAmount.replace(/,/g, '')
          price = parseFloat(price)
          if (price > 0) {
            if (!this.productOptions[group].options[item].pAdjustType) {
              price = Math.abs(price) * -1
            }
            this.order.selectedOptions[group + ',' + item].price = price
          }
        }
        if (this.productOptions[group].options[item].cps) if (this.productOptions[group].options[item].cps.item) this.order.selectedOptions[group + ',' + item].cps = this.productOptions[group].options[item].cps.item.id
        if (this.productOptions[group].options[item].foreup) {
          this.order.selectedOptions[group + ',' + item].foreup = {
            id: this.productOptions[group].options[item].foreup.id,
            type: this.productOptions[group].options[item].foreup.type,
            isPass: this.productOptions[group].options[item].foreup.attributes.isPass,
            price: this.productOptions[group].options[item].foreup.attributes.basePrice
          }
        }
      }
      this.calculateTotal()
    },
    selectOption (field, groupItem) {
      var gi = groupItem.split(',')
      var group = gi[0]
      var item = gi[1]
      var vm = this
      // delete all the options in this group, since this is a single select
      Object.keys(vm.order.selectedOptions).forEach(function (k) {
        if (k.startsWith(group + ',')) {
          delete vm.order.selectedOptions[k]
        }
      })
      // we wont have an item if just the select group is passed in
      if (item) {
        this.order.selectedOptions[groupItem] = {}
        this.order.selectedOptions[groupItem].name = this.productOptions[group].options[item].name
        this.order.selectedOptions[groupItem].field = field
        var price = this.productOptions[group].options[item].pAdjustAmount.replace(/,/g, '')
        price = parseFloat(price)
        if (price > 0) {
          if (!this.productOptions[group].options[item].pAdjustType) {
            price = Math.abs(price) * -1
          }
          this.order.selectedOptions[group + ',' + item].price = price
        }
        if (this.productOptions[group].options[item].cps) if (this.productOptions[group].options[item].cps.item) this.order.selectedOptions[groupItem].cps = this.productOptions[group].options[item].cps.item.id
      }
      this.calculateTotal()
    },
    calculateTotal () {
      var t = 0
      var vm = this
      Object.keys(vm.order.selectedOptions).forEach(function (k) {
        if (vm.order.selectedOptions[k].price) t = t + vm.order.selectedOptions[k].price
      })
      if (vm.product.price) {
        var p
        if (typeof vm.product.price === 'string') {
          p = vm.product.price.replace(/,/g, '')
          p = p.replace(/$/g, '')
          p = p.replace(/€/g, '')
          p = parseFloat(p)
        } else {
          p = parseFloat(vm.product.price)
        }
        t += p
      }
      t = t * parseInt(vm.order.totalToBuy)
      vm.order.total = t
      this.textTotal = this.currency.symbol
      if (t.toFixed(2) === 'NaN') this.textTotal += '0.00'
      else this.textTotal += t.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      this.order.totalString = this.textTotal
    },
    increaseQty () {
      if ((this.totalToBuy < this.quantities.left) || (this.quantities.left === null)) this.totalToBuy++
    },
    decreaseQty () {
      if (this.totalToBuy > 1) this.totalToBuy--
    }
  },
  inject: {
    $validator: '$validator'
  }
}
</script>

<style scoped>
.oto-edit-screen {
  opacity: 0.3;
}
.oto-qty-picker-holder {
  margin-top: 15px;
}
.oto-qty-picker {
  max-width: 60px!important;
  text-align: center;
}
.oto-product-image > img {
  max-width: 150px;
}
.oto-product-form {
  margin-bottom: 20px;
}
.oto-product-title {
  font-weight: bold;
  font-size: 18px;
}
.oto-product-form > div {
  margin: 10px 0;
}
</style>
