<template>
  <div class="oto-credit-card">
    <button
        class="button is-success"
        :class="{'is-loading': processing}"
        @click="startCheckout">
        Continue to Billing Details
    </button>
    <div class="secure-text-holder is-hidden-touch">
      <div class="icon has-text-grey">
        <i class="fas fa-lock "/>
      </div>
      <div class="secure-text has-text-grey">
        All data is transmitted encrypted over a secure TLS connection
      </div>
    </div>
  </div>
</template>

<script>
import VueScript2 from 'vue-script2'
import analytics from '@/mixins/Analytics.js'

export default {
  mixins: [analytics],
  props: {
    // the payroc api username
    // required always
    apiUsername: {
      type: String,
      required: true
    },
    // the amount to charge in nn format, i.e. passing 4500 is $45.00
    amount: {
      type: Number,
      required: false,
      default: 0
    },
    // the total label, string, to display
    totalLabel: {
      type: String,
      required: false,
      default: 'Total'
    },
    // boolean to tell the component to display is processing (disabled) mode
    processing: {
      type: Boolean,
      required: false,
      default: false
    },
    // boolean to tell the component to auto open/launch when mounted
    autolaunch: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  mounted () {
    // Load PayRoc JS
    var vm = this
    VueScript2.load('https://js.itransact.com/itransact.js').then(function () { vm.render() })
  },
  methods: {
    render () {
      if (this.autolaunch) this.startCheckout()
    },
    startCheckout () {
      if (process.env.NODE_ENV !== 'development') this.analyticsStartPayment('payroc')
      var vm = this
      var options = {
        apiUsername: vm.apiUsername,
        name: vm.totalLabel,
        billingAddress: true,
        theme: '#4d9933',
        onToken: (data, args) => {
          var res = data
          if (args) res = { ...data, ...args }
          vm.$emit('response', res)
        }
      }
      var handler = window.iTransact.Checkout.init(options)
      handler.render({
        amount: vm.amount
      })
    }
  }
}
</script>
