import Vue from 'vue'
import Router from 'vue-router'
import Home from '@/components/Home.vue'
import Aggregate from '@/components/Aggregate.vue'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [
    { path: '/:slug', name: 'home', component: Home },
    { path: '/:folder/:slug', name: 'aggregate', component: Aggregate }
  ]
})

export default router
