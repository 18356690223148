<template>
  <div
    ref="ets"
    :class="{ 'hideMe': etsSuccess }"
    :data-ets-key="key"
    :data-amount="amount"
    :data-customer-name="name"
    :data-avs-street="street"
    :data-avs-zip="zip"
    :data-correlation-id="id"
    data-prompt-for-avs="false"
    data-allow-avs-skip="false"
    data-entry-type="KEYED_CARD_PRESENT"
    data-payment-type="CHARGE"
    data-payment-type-order="0"/>
</template>

<script>
import analytics from '@/mixins/Analytics.js'

export default {
  mixins: [analytics],
  props: {
    etsKey: {
      type: String,
      required: true
    },
    etsAmount: {
      type: Number,
      required: true
    },
    custName: {
      type: String,
      required: false
    },
    custStreet: {
      type: String,
      required: false
    },
    custZip: {
      type: String,
      required: false
    },
    transId: {
      type: String,
      required: false
    },
    cards: {
      type: Object,
      required: false
    },
    data: {
      type: Object,
      required: true
    },
    order: {
      type: Object,
      required: true
    }
  },
  data: function () {
    return {
      key: this.etsKey,
      amount: this.etsAmount,
      name: this.custName,
      street: this.custStreet,
      zip: this.custZip,
      id: this.transId,
      cardsToShow: this.cards,
      etsSuccess: null,
      etsError: null
    }
  },
  computed: {
    showVisa: function () {
      return this.cards.visa
    },
    showMasterCard: function () {
      return this.cards.mastercard
    },
    showJcb: function () {
      return this.cards.jcb
    },
    showDiscover: function () {
      return this.cards.discover
    },
    showAmex: function () {
      return this.cards.amex
    }
  },
  mounted () {
    this.$nextTick(function () {
      window.$(this.$refs.ets).hp({
        showVisa: this.showVisa,
        showMasterCard: this.showMasterCard,
        showJcb: this.showJcb,
        showDiscover: this.showDiscover,
        showAmex: this.showAmex,
        showGift: false,
        showEMoney: false,
        showDiners: false,
        successCallback: this.handleTransactionSuccess,
        errorCallback: this.handleTransactionError
      })
      setTimeout(function () {
        if (document.getElementsByName('ccname')[0]) document.getElementsByName('ccname')[0].setAttribute('tabindex', '1')
        if (document.getElementsByName('cardnumber')[0]) document.getElementsByName('cardnumber')[0].setAttribute('tabindex', '2')
        if (document.getElementsByName('cc-exp')[0]) document.getElementsByName('cc-exp')[0].setAttribute('tabindex', '3')
        if (document.getElementsByName('cc-exp')[1]) document.getElementsByName('cc-exp')[1].setAttribute('tabindex', '4')
        if (document.getElementsByName('cvc')[0]) document.getElementsByName('cvc')[0].setAttribute('tabindex', '5')
      }, 2000)
      if (process.env.NODE_ENV !== 'development') this.analyticsStartPayment('ets')
    })
  },
  methods: {
    handleTransactionSuccess (response) {
      this.order.payment = response
      this.order.payment.engineType = 'ets'
      this.etsSuccess = response
      this.$emit('confirm', true)
    },
    handleTransactionError (error) {
      this.order.payment = error
      this.etsError = null
      if (error) {
        if (error.message) {
          this.etsError = error.message
        }
      }
      this.$emit('error', this.etsError)
    }
  }
}
</script>

<style scoped>
  .hideMe {
    display: none!important;
  }
</style>
