<template>
  <div
    v-if="loaded"
    class="aggregate">
    <div id="pageTop"/>
    <!-- NAVBAR AREA -->
    <div
      v-if="showNavbar"
      class="container">
      <nav class="navbar is-spaced">
        <div class="navbar-brand">
          <div class="navbar-item">
            <img
              v-if="data.account.logoWide"
              :src="data.account.logoWide"
              :alt="'Logo for ' + data.account.name">
          </div>
        </div>
        <div class="navbar-menu">
          <div class="navbar-start">
          </div>
          <div class="navbar-end">
            <div class="navbar-item">
              <Share :props="shareProps"/>
            </div>
          </div>
        </div>
      </nav>
    </div>
    <!-- HEADLINE AREA -->
    <div v-if="showHeadline">
      <section
        v-for="(headline, hindex) in data.headline"
        :key="'h'+ hindex"
        :style="{ backgroundColor: JSON.parse(headline.colors).primary }"
        :class="{ 'module-mode': moduleMode }"
        class="hero">
        <div class="hero-body">
          <div class="container">
            <section
              :style="{ backgroundColor: JSON.parse(headline.colors).primary }"
              class="section">
              <div class="columns">
                <div class="column is-8 is-offset-2">
                  <div class="content is-medium">
                    <h1
                      class="title is-2"
                      :style="{ color: JSON.parse(headline.colors).primaryFont }">
                      {{ headline.title }}
                    </h1>
                    <h2
                      class="subtitle is-4"
                      :style="{ color: JSON.parse(headline.colors).primaryFont }">
                      {{ headline.post_text }}
                    </h2>
                    <a :href="buildLandingPageURL(headline.slug, 'head')">
                      <figure class="image is-16by10 is-marginless">
                        <img
                          :src="headline.postimage"
                          class="is-rounded-a-bit" />
                      </figure>
                    </a>
                    <p
                      class="margin-top"
                      :style="{ color: JSON.parse(headline.colors).primaryFont }">
                      {{ headline.description }}
                    </p>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </section>
    </div>
    <!-- FOLLOW AREA -->
    <div v-if="showFollow">
      <section
        :class="{'has-background-light':!showSalesAndEvents}"
        class="hero">
        <div class="hero-body">
          <div class="container">
            <section
              :class="{'has-background-light':!showSalesAndEvents}"
              class="section">
              <div class="columns">
                <div class="column is-8 is-offset-2">
                  <div class="content is-medium has-text-centered">
                    <h2 class="title is-3">Follow Us For The Latest News & Deals</h2>
                    <div class="column is-6 is-offset-3">
                      <div class="oto-follow-buttons are-small">
                        <a
                          v-if="data.account.facebook_url"
                          class="is-rounded"
                          target="_blank"
                          :href="data.account.facebook_url">
                          <span class="icon is-large">
                            <i class="fab fa-facebook fa-3x"/>
                          </span>
                        </a>
                        <a
                          v-if="data.account.twitter_url"
                          class="is-rounded"
                          target="_blank"
                          :href="data.account.twitter_url">
                          <span class="icon is-large">
                            <i class="fab fa-twitter fa-3x"/>
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </section>
    </div>
    <!-- SALES AND EVENTS AREA -->
    <div v-if="showSalesAndEvents">
      <section
        :class="{ 'module-mode': moduleMode }"
        class="hero has-background-light">
        <div class="hero-body">
          <div class="container">
            <section class="section has-background-light">
              <div class="columns">
                <div class="column is-8 is-offset-2">
                  <div class="content is-medium">
                    <div class="columns is-variable is-8">
                      <div class="column is-half">
                        <h2 class="title is-2 large-margin">Recent Sales</h2>
                        <div
                          v-if="saleHTML"
                          v-html="saleHTML"
                          class="card-group"></div>
                        <h2
                          v-else
                          class="title is-5">No sales right now. Check back soon!</h2>
                      </div>
                      <div
                        v-if="saleHTML && eventHTML"
                        class="is-hidden-tablet column is-paddingless large-margin">
                      </div>
                      <div class="column is-half">
                        <h2 class="title is-2 large-margin">Upcoming Events</h2>
                        <div
                          v-if="eventHTML"
                          v-html="eventHTML"
                          class="timeline has-text-left"></div>
                        <h2
                          v-else
                          class="title is-5">No upcoming events right now. Check back soon!</h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </section>
    </div>
    <!-- OTHER POSTS AREA -->
    <div v-if="showPosts">
      <section class="hero">
        <div class="hero-body">
          <div class="container">
            <div id="postTop"/>
            <section class="section">
              <div class="columns">
                <div class="column is-8 is-offset-2">
                  <h2 class="title is-2 has-text-centered large-margin">Other News</h2>
                  <div class="columns is-desktop is-multiline is-variable is-8">
                    <div
                      v-for="(post, pindex) in otherPosts"
                      :key="'p-'+pindex"
                      class="column is-half-desktop">
                      <div class="content is-medium">
                        <h1 class="title has-text-black is-4">{{ post.title }}</h1>
                        <h2 class="subtitle is-5 has-text-grey">{{ post.post_text }}</h2>
                        <a :href="buildLandingPageURL(post.slug, 'posts')">
                          <figure class="image is-16by10 is-marginless">
                            <img
                              :src="post.postimage"
                              class="is-rounded-a-bit-less" />
                          </figure>
                        </a>
                        <p
                          v-html="truncate(post.description, maxDescriptionLength, post.slug)"
                          class="has-text-dark"></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section
              v-if="totalPages > 1"
              class="section">
              <div class="columns">
                <div class="column is-8 is-offset-2">
                  <nav class="pagination">
                    <a
                      :disabled="currentPage <= 1"
                      class="pagination-previous"
                      @click="gotoPage(currentPage - 1)">
                      <span class="icon">
                        <i class="fas fa-arrow-left"/>
                      </span>
                    </a>
                    <a
                      :disabled="currentPage >= totalPages"
                      class="pagination-next"
                      @click="gotoPage(currentPage + 1)">
                      <span class="icon">
                        <i class="fas fa-arrow-right"/>
                      </span>
                    </a>
                    <ul class="pagination-list">
                      <li
                        v-for="page in totalPages"
                        :key="'page-link-' + page">
                        <a
                          :class="{ 'is-current': currentPage === page }"
                          class="pagination-link"
                          @click="gotoPage(page)">
                          {{ page }}
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </section>
          </div>
        </div>
      </section>
    </div>
    <!-- FOOTER AREA -->
    <footer v-if="showFooter">
      <div class="section">
        <div class="container">
          <div class="columns">
            <div class="column">
              <div class="columns">
                <div
                  v-if="account"
                  class="column">
                  <img
                    v-if="account.logoWide"
                    :src="account.logoWide"
                    class="oto-footer-logo"
                    :alt="'Logo for ' + account.name">
                </div>
              </div>
              <div class="columns">
                <div
                  v-if="accountLocationHTML"
                  class="column">
                  <p class="has-text-weight-bold has-text-black">Location</p>
                  <p v-html="accountLocationHTML" />
                </div>
              </div>
              <div class="columns">
                <div
                  v-if="account"
                  class="column">
                  <p
                    v-if="account.phone"
                    class="has-text-weight-bold has-text-black">Phone</p>
                  <p v-if="account.phone">{{ account.phone }}</p>
                </div>
              </div>
            </div>
            <div
              v-if="googleMaps"
              class="column">
              <iframe
                title="Google Map"
                height="320"
                class="oto-map-height-fix"
                :src="googleMaps"
                allowfullscreen>
              </iframe>
            </div>
          </div>
        </div>
      </div>
      <div class="section has-background-white-ter">
        <div class="container">
          <div class="has-text-centered">Powered by
            <a href="https://campaignpilot.com" target="_blank">
              CampaignPilot
            </a>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import layouts from '@/mixins/Layouts-Aggregate.js'
export default {
  mixins: [layouts]
}
</script>
