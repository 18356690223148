import engineApi from '@/plugins/engine-api.js'
import xhr from '@/mixins/Xhr.js'
import Share from '@/components/elements/Share'

export default {
  props: {
    data: {
      type: Object,
      required: true
    },
    slug: {
      type: String,
      required: true
    }
  },
  components: {
    Share
  },
  mixins: [xhr],
  data () {
    return {
      loaded: false,
      iframeMode: false,
      moduleMode: null,
      account: null,
      totalPages: null,
      currentPage: 1,
      upcomingEvents: null,
      recentSales: null,
      maxDescriptionLength: 200,
      headlinePosts: [],
      otherPosts: []
    }
  },
  computed: {
    showNavbar: function () {
      if (this.iframeMode || this.moduleMode) return false
      return true
    },
    showHeadline: function () {
      if (this.moduleMode) {
        if (this.moduleMode === 'headline') if (this.data) if (this.data.headline) if (this.data.headline.length > 0) return true
        return false
      } else {
        if (this.data) if (this.data.headline) if (this.data.headline.length > 0) return true
        return false
      }
    },
    showFollow: function () {
      if (this.iframeMode || this.moduleMode) return false
      else if (this.data) if (this.data.account) if (this.data.account.facebook_url || this.data.account.twitter_url) return true
      return false
    },
    showSalesAndEvents: function () {
      if (this.moduleMode) {
        if (this.moduleMode === 'upcoming') if (this.eventHTML || this.saleHTML) return true
        return false
      } else {
        if (this.eventHTML || this.saleHTML) return true
        return false
      }
    },
    showPosts: function () {
      if (this.moduleMode) {
        if (this.moduleMode === 'posts') if (this.otherPosts) if (this.otherPosts.length > 0) return true
        return false
      } else {
        if (this.otherPosts) if (this.otherPosts.length > 0) return true
        return false
      }
    },
    showFooter: function () {
      if (this.iframeMode || this.moduleMode) return false
      return true
    },
    shareProps: function () {
      return {
        url: window.location,
        title: 'Check this out!'
      }
    },
    accountLocationHTML: function () {
      if (this.account) {
        if (this.account.street && this.account.city && this.account.state && this.account.postal) {
          var l = this.account.street
          if (this.account.street2) l = l + '<br>' + this.account.street2
          l = l + '<br>' + this.account.city + ', ' + this.account.state + ' ' + this.account.postal
          return l
        }
      }
      return null
    },
    googleMaps: function () {
      if (this.account) {
        if (this.account.street && this.account.city && this.account.state && this.account.postal) return encodeURI('https://www.google.com/maps/embed/v1/place?key=' + process.env.VUE_APP_GOOGLE_MAPS_KEY + '&q=' + this.account.street + ',' + this.account.city + ',' + this.account.state + ' ' + this.account.postal + '&attribution_source=' + process.env.VUE_APP_GOOGLE_MAPS_ATTRIBUTION)
      }
      return null
    },
    accountColors: function () {
      var colors = null
      if (this.account) {
        if (this.account.style_colors) {
          colors = JSON.parse(this.account.style_colors)
        }
      }
      if (this.headlinePosts) {
        if (this.headlinePosts.length > 0) {
          if (this.headlinePosts[0].colors) {
            colors = JSON.parse(this.headlinePosts[0].colors)
          }
        }
      }
      return colors
    },
    eventHTML: function () {
      var eh = null
      if (this.upcomingEvents) {
        if (this.upcomingEvents.length > 0) {
          var lastLabel = null
          eh = ''
          var vm = this
          this.upcomingEvents.forEach(function (event) {
            var eurl = vm.buildLandingPageURL(event.slug, 'events')
            if (event.dateLabel !== lastLabel) {
              eh += '<header class="timeline-header"><span class="tag is-primary">' + event.dateLabel + '</span></header>'
              lastLabel = event.dateLabel
            }
            eh += '<div class="timeline-item is-primary">'
            eh += '<div class="timeline-marker is-icon">'
            eh += '<i class="fa fa-flag"></i>'
            eh += '</div>'
            eh += '<div class="timeline-content">'
            eh += '<p class="heading">' + event.dateHuman + '</p>'
            eh += '<p><a href="' + eurl + '">' + event.title + '</a></p>'
            eh += '</div>'
            eh += '</div>'
          })
        }
      }
      return eh
    },
    saleHTML: function () {
      var sh = null
      if (this.recentSales) {
        if (this.recentSales.length > 0) {
          sh = ''
          var vm = this
          this.recentSales.forEach(function (sale) {
            var surl = vm.buildLandingPageURL(sale.slug, 'sales')
            var sprice = vm.buildPrice(sale.variables)
            sh += '<div class="card is-rounded-a-bit-less">'
            sh += '<div class="card-content">'
            sh += '<p class="title is-5">' + sale.title + '</p>'
            if (sprice) sh += '<p class="subtitle is-5">' + sprice + '</p>'
            sh += '</div>'
            sh += '<footer class="card-footer">'
            sh += '<p class="card-footer-item">'
            sh += '<span>'
            sh += '<a href=' + surl + '>Buy Now</a>'
            sh += '</span>'
            sh += '</p>'
            sh += '</footer>'
            sh += '</div>'
          })
        }
      }
      return sh
    }
  },
  created () {
    if (this.$route.query) if (this.$route.query.iframe) this.iframeMode = true
    if (this.$route.query) this.moduleMode = this.$route.query.module
    if (this.data) {
      this.account = this.data.account
      if (this.data.headline) if (this.data.headline.length > 0) this.headlinePosts = this.data.headline
      this.loaded = true
      this.fetchPosts()
      this.fetchEvents(3)
      this.fetchSales(2)
    }
  },
  methods: {
    fetchPosts (scrollTo) {
      var vm = this
      engineApi.fetchPosts(
        this.slug, null, null, 1, this.currentPage,
        function (err, response) {
          if (err) {
            vm.handleXhrError(err)
          } else {
            if (response) {
              vm.otherPosts = response.results
              vm.totalPages = response.pages
              if (scrollTo) vm.$scrollTo('#postTop', 200, { easing: 'ease' })
            }
          }
        }
      )
    },
    fetchEvents (limit) {
      var vm = this
      engineApi.fetchEvents(
        this.slug, limit,
        function (err, response) {
          if (err) {
            vm.handleXhrError(err)
          } else {
            if (response) {
              vm.upcomingEvents = response.results
            }
          }
        }
      )
    },
    fetchSales (limit) {
      var vm = this
      engineApi.fetchSales(
        this.slug, limit,
        function (err, response) {
          if (err) {
            vm.handleXhrError(err)
          } else {
            if (response) {
              vm.recentSales = response.results
            }
          }
        }
      )
    },
    gotoPage (page) {
      if (page < 1) page = 1
      if (page > this.totalPages) page = this.totalPages
      if (page !== this.currentPage) {
        this.currentPage = page
        this.fetchPosts(true)
      }
    },
    buildLandingPageURL (slug, utmContent) {
      var u = process.env.VUE_APP_THIS_DOMAIN
      u += '/' + slug
      u += '?utm_source=campaignpilot&utm_medium=aggregate&utm_campaign=' + slug
      if (utmContent) u += '&utm_content=' + utmContent
      return u
    },
    buildPrice (variables) {
      if (variables) {
        if (typeof variables === 'string') variables = JSON.parse(variables)
        if (variables.price) {
          return variables.price
        }
      }
      return null
    },
    truncate (text, length, slug) {
      if (text && length && slug) {
        var clamp = ' ... <a href="' + this.buildLandingPageURL(slug) + '">read more</a>'
        return text.length > length ? text.slice(0, length) + clamp : text
      }
      return null
    }
  }
}
