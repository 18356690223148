<template>
  <div
    v-if="contentReady && account"
    :style="cssProps"
    class="page-wrapper oto-lp2">
    <!-- HEADER AND NAVBAR -->
    <div class="container">
      <nav class="navbar is-spaced">
        <div class="navbar-brand">
          <div class="navbar-item">
            <img
              v-if="account.logoWide"
              :src="account.logoWide"
              :alt="'Logo for ' + account.name">
          </div>
        </div>
        <div class="navbar-menu">
          <div class="navbar-start">
          </div>
          <div class="navbar-end is-mobile">
            <div class="navbar-item">
              <Share :props="shareProps"/>
            </div>
          </div>
        </div>
      </nav>
    </div>
    <div class="oto-top-background-color" :style="{ backgroundColor: theme.currentColors.primary }">
    </div>
    <div class="container oto-container-main">
      <section class="section is-paddingless">
        <div class="columns is-marginless">
          <!-- IMAGE BACKGROUND, TITLE, DESCRIPTION, CALL TO ACTION -->
          <div class="column is-paddingless">
            <figure class="image is-16by9 oto-hero-img-surround">
              <img
                class="oto-hero-img"
                :src="images[0]">
            </figure>
          </div>
          <div class="column is-two-fifths has-background-white-ter oto-event-details">
            <div
              v-if="currentTitle"
              :class="{ 'oto-editable': editMode && numberOfPhrases('title') > 1 }"
              @click="changePhrase('title')">
              <div
                v-if="editMode && numberOfPhrases('title') > 1"
                class="oto-editable-button">
                <a class="tag is-light">
                  <span class="icon is-small has-text-white">
                    <i class="fas fa-sync-alt"/>
                  </span>
                </a>
              </div>
              <h1
                :class="theme.currentFont.class"
                :style="{color: theme.currentColors.primary}"
                class="title is-size-3-tablet has-text-weight-normal">
                {{ currentTitle }}
              </h1>
            </div>
            <div
              v-if="currentHeading"
              :class="{ 'oto-editable': editMode && numberOfPhrases('heading') > 1 }"
              @click="changePhrase('heading')">
              <div
                v-if="editMode && numberOfPhrases('heading') > 1"
                class="oto-editable-button">
                <a class="tag is-light">
                  <span class="icon is-small has-text-white">
                    <i class="fas fa-sync-alt"/>
                  </span>
                </a>
              </div>
              <div class="is-uppercase has-text-weight-semibold oto-uppercase-letter-spacing">
                <p :class="theme.currentFont.class">{{ currentHeading }}</p>
              </div>
            </div>
            <div
              v-if="showEndCountdown"
              class="oto-lp2 oto-flip-ends-holder">
              <div class="oto-flip-header">
                <span v-if="tickets">Act Fast! Registration Ends In:</span>
                <span v-else-if="product">Act Fast! Sale Ends In:</span>
              </div>
              <flip-countdown :deadline="flipEndDate"/>
            </div>
            <div
              v-if="showActionButton"
              :class="{ 'oto-editable': editMode && numberOfPhrases('action') > 1 }"
              @click="changePhrase('action')">
              <div
                v-if="editMode && numberOfPhrases('action') > 1"
                class="oto-editable-button">
                <a class="tag is-light">
                  <span class="icon is-small has-text-white">
                    <i class="fas fa-sync-alt"/>
                  </span>
                </a>
              </div>
              <div class="oto-hero-button-surround">
                <a
                  class="button is-large is-size-6 is-uppercase is-fullwidth has-text-weight-bold is-marginless oto-uppercase-letter-spacing oto-trans-border"
                  href="#action-area"
                  :style="{ backgroundColor: '' + theme.currentColors.secondary + '', color: '' + theme.currentColors.secondaryFont + ''}">
                  {{ currentAction }}
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="section is-paddingless">
        <!-- HTML AREA -->
        <div class="columns is-centered is-marginless oto-section-columns-padding">
          <div class="column is-three-fifths o-content-area">
            <!-- CUSTOM RICH CONTENT -->
            <div
              v-if="currentContent"
              :class="{ 'oto-editable-no-hover': editMode }">
              <div
                v-if="editMode"
                class="oto-editable-html-button"
                @click="toggleRichEdit()">
                <a class="tag has-addons">
                  <span class="icon is-small has-text-white">
                      <i class="fas fa-pen"/>
                    </span>
                  <span class="has-text-white">Edit Content</span>
                </a>
              </div>
              <Editor
                v-if="editMode && richEdit"
                v-model="richContent"
                :api-key="tinymceKey"
                :init="tinyoptions"/>
              <div
                v-else-if="editMode"
                @click="toggleRichEdit()">
                <div class="content">
                  <p v-html="currentContent"></p>
                </div>
              </div>
              <div
                v-else
                class="content">
                <p v-html="currentContent"></p>
              </div>
            </div>
            <!-- AUTOGEN DATE, LOCATION -->
            <div class="columns">
              <div
                v-if="date"
                class="column o-date-holder">
                <p>Date</p>
                <p>{{ date }}</p>
                <a
                  v-if="iCalUrl"
                  :style="{color: theme.currentColors.secondary}"
                  class="has-text-weight-bold"
                  :href="iCalUrl">Add to Calendar</a>
              </div>
              <div
                v-if="action === 'event' && eventLocationHTML"
                class="column o-location-holder">
                <p>Location</p>
                <span
                  v-html="eventLocationHTML"
                  class="o-location"/>
                <div
                  v-if="eventDirectionsURL"
                  class="o-directions">
                  <a
                    :href="eventDirectionsURL"
                    :style="{color: theme.currentColors.secondary}"
                    class="has-text-weight-bold no-print"
                    target="_blank">Get Directions</a>
                </div>
              </div>
            </div>
            <!-- AUTOGEN TICKET TABLE -->
            <div
              v-if="ticketPriceTable"
              class="o-ticket-price-table-holder"
              v-html="ticketPriceTable"/>
          </div>
        </div>
        <!-- BUTTON AREA -->
        <div
          v-if="embedButton"
          class="columns is-centered is-marginless oto-section-button-padding">
          <div
            :style="buttonAlign"
            class="column is-three-fifths o-content-area">
            <a
              class="button is-marginless is-large is-size-6 is-uppercase has-text-weight-bold oto-uppercase-letter-spacing oto-trans-border is-fullwidth-mobile"
              target="_blank"
              :href="embedButton.url"
              :style="{ backgroundColor: '' + theme.currentColors.secondary + '', color: '' + theme.currentColors.secondaryFont + ''}">
              {{ embedButton.text }}
            </a>
          </div>
        </div>
        <!-- ACTION AREA -->
        <a id="action-area"/>
        <div
          v-if="showActionArea"
          class="columns is-centered is-marginless oto-section-columns-padding oto-form-area has-background-white-ter">
          <div class="column is-half-desktop is-two-thirds-tablet">
            <h2
              :class="theme.currentFont.class"
              class="title is-size-2 has-text-centered has-text-weight-normal"
              :style="{color: theme.currentColors.primary}"
              style="margin-bottom: 1rem;">
              {{ currentAction }}
            </h2>
            <h3 class="title is-size-5 has-text-centered is-uppercase has-text-weight-bold oto-uppercase-letter-spacing oto-action-subtitle">
              {{ currentTitle }}
            </h3>
            <!-- TICKETS / PRODUCT / FORM ACTION -->
            <div>
              <div id="messageArea"/>
              <div v-if="confirmMessage">
                <div class="notification is-primary">
                  {{ confirmMessage }}
                </div>
              </div>
              <div v-else>
                <div v-if="errorMessage">
                  <div class="notification is-danger">
                    {{ errorMessage }}
                  </div>
                </div>
                <div
                  v-if="!isPurchaseOpen && timeToPurchase && flipStartDate"
                  class="oto-lp2 oto-flip-starts-holder">
                  <div class="oto-flip-header">
                    <span v-if="tickets">Registration Starts In:</span>
                    <span v-else-if="product">Sale Starts In:</span>
                  </div>
                  <flip-countdown :deadline="flipStartDate"/>
                </div>
                <div
                  v-else-if="!isPurchaseOpen && qtyLeft <= 0 && qtyLeft !== null"
                  class="font-opensans title is-size-5 has-text-centered has-text-weight-bold"
                  style="color: red;">
                  <span v-if="tickets">We're sorry but this event is sold out.</span>
                  <span v-else-if="product">We're sorry but this item is sold out.</span>
                </div>
                <div
                  v-else-if="!isPurchaseOpen"
                  class="font-opensans title is-size-5 has-text-centered has-text-weight-bold"
                  style="color: red;">
                  <span v-if="tickets">We're sorry but registration for this event is now closed.</span>
                  <span v-else-if="product">We're sorry but this sale is no longer available.</span>
                </div>
                <form
                  v-else
                  @submit.prevent="submitForm">
                  <div v-if="subtype === 'paid'">
                    <!-- TICKET REGISTRATION -->
                    <div
                      v-if="availableTickets"
                      class="oto-event-holder">
                      <RenderRegistration
                        :editMode="editMode"
                        :registration="registration"
                        :tickets="availableTickets"
                        :ticketAddons="ticketAddons"
                        :questions="questions"
                        :theme="theme"
                        :order="order"
                        :quantities="quantities"
                        :currency="currencySettings"
                        v-model="selectedQuestion"
                        @refresh="postSelectedQuestion()"
                      />
                    </div>
                    <!-- PRODUCT PURCHASE -->
                    <div
                      v-else-if="product"
                      class="oto-product-holder">
                      <RenderProduct
                        :editMode="editMode"
                        :product="product"
                        :productOptions="productOptions"
                        :questions="questions"
                        :theme="theme"
                        :order="order"
                        :quantities="quantities"
                        :currency="currencySettings"
                        v-model="selectedQuestion"
                        @refresh="postSelectedQuestion()"
                      />
                    </div>
                  </div>
                  <!-- FORM -->
                  <div
                    v-else-if="questions"
                    class="oto-form-holder">
                    <RenderForm
                      v-model="selectedQuestion"
                      :editMode="editMode"
                      :questions="questions"
                      :label="{ style: { color: theme.currentColors.primaryFont } }"
                      :addOpt="useOpt"
                      @opt="optStatusChanged"
                      @refresh="postSelectedQuestion()"/>
                  </div>
                  <button
                    :disabled="isLoading || editMode"
                    :class="buttonClass"
                    class="button is-large is-size-6 is-fullwidth oto-trans-border is-uppercase has-text-weight-bold oto-uppercase-letter-spacing"
                    :style="{ backgroundColor: '' + theme.currentColors.secondary + '', color: '' + theme.currentColors.secondaryFont + ''}">
                    <span v-if="subtype === 'paid'">Checkout</span>
                    <span v-else>{{ currentAction }}</span>
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
        <!-- IFRAME AREA -->
        <div v-if="embedIFrame">
          <div :style="iframeHolderStyle">
            <iframe
              :src="embedIFrame.url"
              :style="iframeStyle"/>
          </div>
        </div>
      </section>
      <footer v-if="account">
        <div
          v-if="accountLocationHTML || account.phone"
          :class="{ 'has-background-white-ter': !showActionArea }"
          class="columns is-gapless">
          <div class="column has-text-centered oto-footer-left">
            <div>
               <h3 class="has-text-weight-bold has-text-black">{{ account.name }}</h3>
            </div>
            <div v-if="accountLocationHTML">
              <p class="has-text-weight-bold has-text-black">Location</p>
              <p v-html="accountLocationHTML" />
            </div>
            <div>
                <p
                  v-if="account.phone"
                  class="has-text-weight-bold has-text-black">Phone</p>
                <p v-if="account.phone">{{ account.phone }}</p>
            </div>
          </div>
          <div
            v-if="googleMaps"
            class="column">
            <iframe
              title="Google Map"
              height="320"
              class="oto-map-height-fix"
              :src="googleMaps"
              allowfullscreen>
            </iframe>
          </div>
        </div>
      </footer>
    </div>
    <div class="section has-background-white-ter oto-copyright">
      <div class="container">
        <div class="columns">
          <div class="column">
            <div
              v-if="account"
              class="oto-logo-wide">
              <img
                v-if="account.logoWide"
                :src="account.logoWide"
                :alt="'Logo for ' + account.name">
            </div>
          </div>
          <div class="column has-text-right">
            <p>
              Powered by
              <a href="https://campaignpilot.com" target="_blank">
                CampaignPilot
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="editMode"
      class="oto-click-blocker"/>
  </div>
</template>

<script>
import layouts from '@/mixins/Layouts.js'
export default {
  mixins: [layouts],
  computed: {
    cssProps: function () {
      return {
        '--theme-color-alternate': this.theme.currentColors.alternate,
        '--theme-color-alternateFont': this.theme.currentColors.alternateFont,
        '--theme-color-primary': this.theme.currentColors.primary,
        '--theme-color-primaryFont': this.theme.currentColors.primaryFont,
        '--theme-color-secondary': this.theme.currentColors.secondary,
        '--theme-color-secondaryFont': this.theme.currentColors.secondaryFont,
        '--flip-font-color': this.theme.currentColors.secondaryFont,
        '--flip-top-font-color': this.theme.currentColors.secondaryDarkerFont,
        '--flip-border-color': this.theme.currentColors.secondary,
        '--flip-bg-color': this.theme.currentColors.secondary,
        '--flip-top-bg-color': this.theme.currentColors.secondaryDarker
      }
    }
  }
}
</script>
