<template>
  <div :style="{ color: theme.currentColors.primaryFont }">
    <!-- no tickets, no need to print registration form -->
    <div v-if="tickets.length > 0">
      <div v-if="quantities.left">
        <p
          v-if="quantities.left < 10"
          class="title is-size-5 has-text-centered has-text-weight-bold oto-tickets-gone">
          Gone soon, less than 10 tickets available!
        </p>
        <p
          v-else-if="quantities.left < 20"
          class="title is-size-5 has-text-centered has-text-weight-bold oto-tickets-gone">
          Gone soon, less than 20 tickets available!
        </p>
      </div>
      <!-- TICKET ORDER FORM -->
      <div class="field">
        <label
          :style="{ color: theme.currentColors.primaryFont }"
          class="label">Ticket Quantity</label>
        <div class="field has-addons oto-qty-picker-holder">
          <div class="control">
            <a
              :style="{ backgroundColor: '' + theme.currentColors.secondary + '', color: '' + theme.currentColors.secondaryFont + ''}"
              class="button is-primary oto-trans-border oto-quantity-button"
              @click="decreaseTickets">-</a>
          </div>
          <div class="control">
            <input
              v-validate="{ required: true }"
              id="totalToBuy"
              ref="totalToBuy"
              v-model="numberOfTickets"
              :class="{ 'is-danger': errors.has('totalToBuy') }"
              name="totalToBuy"
              class="input oto-qty-picker"
              type="text"
              placeholder=""
              readonly>
          </div>
          <div class="control">
            <a
              :style="{ backgroundColor: '' + theme.currentColors.secondary + '', color: '' + theme.currentColors.secondaryFont + ''}"
              class="button is-primary oto-trans-border oto-quantity-button"
              @click="increaseTickets">+</a>
          </div>
        </div>
      </div>
      <!-- TICKET ORDER DETAILS -->
      <div
        v-if="numberOfTickets > 0"
        class="o-ticket-group">
        <div
          v-for="(t, index) in order.ticketOrder"
          :key="'t' + index"
          class="o-ticket">
          <div class="field">
            <label
              v-if="numberOfTickets > 1"
              :style="{ color: theme.currentColors.primaryFont }"
              class="label">Ticket {{ index + 1 }}</label>
            <label
              v-else
              :style="{ color: theme.currentColors.primaryFont }"
              class="label">Your Ticket</label>
            <div
              v-if="tickets.length > 1"
              class="control">
              <div class="select">
                <select @change="selectTicket(index, $event.target.value)">
                  <option>Select a ticket type</option>
                  <option
                    v-for="(ticket, index2) in tickets"
                    :key="'to' + index + index2"
                    :value="index2">
                    {{ ticket.name }} - {{ ticket.priceString }}
                  </option>
                </select>
              </div>
            </div>
            <div
              v-else
              :style="{ color: theme.currentColors.primaryFont }"
              class="">
              {{ tickets[0].name }}
              <span v-if="tickets[0].priceString">({{ tickets[0].priceString }})</span>
            </div>
          </div>
          <div
            v-if="ticketAddons.length > 0"
            class="field">
            <label
              :style="{ color: theme.currentColors.primaryFont }"
              class="label">Ticket Add-Ons</label>
            <div
              v-for="(addon, index3) in ticketAddons"
              :key="'ao' + index + index3"
              class="field">
              <input
                :id="'t' + index + 'ao' + index3"
                :name="'t' + index + 'ao' + index3"
                class="is-checkradio is-dark"
                type="checkbox"
                @click="toggleAddon(index, index3)">
              <label
                :for="'t' + index + 'ao' + index3"
                :style="{ color: theme.currentColors.primaryFont }">
                {{ addon.name }} - {{ addon.priceString }}
              </label>
            </div>
          </div>
          <div v-if="t.questions">
            <RenderForm
              v-if="t.questions.length>0"
              v-model="selected"
              :editMode="editMode"
              :questions="t.questions"
              :label="{ style: { color: theme.currentColors.primaryFont } }"
              @input="postSelectedQuestion()"/>
          </div>
        </div>
      </div>
      <!-- ORDER TOTAL -->
      <div
        v-if="textTotal"
        :style="{ color: theme.currentColors.primaryFont }"
        class="oto-total-order-price-heading">
        <hr class="has-background-grey-lighter">
        Your Total
        <span
          :style="{ color: theme.currentColors.primaryFont }"
          class="oto-total-order-price">
          {{ textTotal }} {{ currency.notation }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import RenderForm from '@/components/global/RenderForm'

export default {
  components: {
    RenderForm
  },
  props: {
    editMode: {
      type: Boolean,
      default: false
    },
    value: {
      type: Number,
      default: null
    },
    registration: {
      type: Object,
      required: true
    },
    tickets: {
      type: Array,
      required: true
    },
    ticketAddons: {
      type: Array,
      required: true
    },
    questions: {
      type: Array,
      required: false,
      default: function () { return [] }
    },
    theme: {
      type: Object,
      required: false,
      default: function () { return {} }
    },
    order: {
      type: Object,
      required: true
    },
    quantities: {
      type: Object,
      required: false,
      default: null
    },
    currency: {
      type: Object,
      required: false,
      default: function () { return {} }
    }
  },
  data () {
    return {
      textTotal: null,
      numberOfTickets: 1,
      selected: null
    }
  },
  watch: {
    numberOfTickets: function (val, oldVal) {
      this.order.totalToBuy = val
      this.createTickets()
    },
    questions: function () {
      // in editMode, reset the tickets anytime the questions change
      if (this.editMode) {
        this.numberOfTickets = 0
        this.order.ticketOrder = []
        this.order.total = null
        this.numberOfTickets = 1
        this.createTickets()
      }
    },
    value (val) {
      this.selected = val
    }
  },
  mounted () {
    this.selected = this.value
    this.order.ticketOrder = []
    this.order.total = null
    this.createTickets()
    // set the current ticket order to the min
    if (this.quantities.min) this.numberOfTickets = this.quantities.min
  },
  methods: {
    postSelectedQuestion () {
      this.$emit('input', this.selected)
    },
    createTickets () {
      var vm = this
      while (vm.order.ticketOrder.length < vm.numberOfTickets) {
        var t = {}
        // only add questions if this is a live rendering
        var q = vm.copy(vm.questions)
        if (q) {
          q.forEach(function (qi) {
            qi.name = qi.name + '_' + (vm.order.ticketOrder.length + 1)
            qi.ref = qi.ref + '_' + (vm.order.ticketOrder.length + 1)
          })
        }
        t = {
          ticket: {},
          addons: {},
          questions: q
        }
        if (vm.tickets.length === 1) {
          // sets ticket to only ticket available
          t.ticket.name = vm.tickets[0].name
          t.ticket.price = vm.tickets[0].price
          t.ticket.priceString = vm.tickets[0].priceString
          var cps = null
          if (vm.tickets[0].cps) if (vm.tickets[0].cps.item) cps = vm.tickets[0].cps.item.id
          t.ticket.cps = cps
          var foreup = null
          if (vm.tickets[0].foreup) {
            foreup = {
              id: vm.tickets[0].foreup.id,
              type: vm.tickets[0].foreup.type,
              isPass: vm.tickets[0].foreup.attributes.isPass,
              price: vm.tickets[0].foreup.attributes.basePrice
            }
          }
          t.ticket.foreup = foreup
        }
        vm.order.ticketOrder.push(t)
      }
      if (vm.order.ticketOrder.length > vm.numberOfTickets) {
        // we have more tickets in the order than we're buying. remove them
        vm.order.ticketOrder.length = vm.numberOfTickets
      }
      if (this.order) {
        this.order.totalToBuy = this.numberOfTickets
      }
      this.calculateTotal()
      this.determineTicketsInCart()
    },
    copy (src) {
      return JSON.parse(JSON.stringify(src))
    },
    selectTicket (orderIndex, ticketIndex) {
      var price = (this.tickets[ticketIndex]) ? this.tickets[ticketIndex].price : null
      var priceString = (this.tickets[ticketIndex]) ? this.tickets[ticketIndex].priceString : null
      var name = (this.tickets[ticketIndex]) ? this.tickets[ticketIndex].name : null
      var cps = null
      if (this.tickets[ticketIndex]) if (this.tickets[ticketIndex].cps) if (this.tickets[ticketIndex].cps.item) cps = this.tickets[ticketIndex].cps.item.id
      var foreup = null
      if (this.tickets[ticketIndex]) {
        if (this.tickets[ticketIndex].foreup) {
          foreup = {
            id: this.tickets[ticketIndex].foreup.id,
            type: this.tickets[ticketIndex].foreup.type,
            isPass: this.tickets[ticketIndex].foreup.attributes.isPass,
            price: this.tickets[ticketIndex].foreup.attributes.basePrice
          }
        }
      }
      if (price || name) {
        this.order.ticketOrder[orderIndex].ticket = {
          name: name,
          price: price,
          priceString: priceString,
          cps: cps,
          foreup: foreup
        }
      } else {
        this.order.ticketOrder[orderIndex].ticket = {}
      }
      this.calculateTotal()
      this.determineTicketsInCart()
    },
    toggleAddon (orderIndex, addOnIndex) {
      if (this.order.ticketOrder[orderIndex].addons[addOnIndex]) {
        delete this.order.ticketOrder[orderIndex].addons[addOnIndex]
      } else {
        this.order.ticketOrder[orderIndex].addons[addOnIndex] = {}
        this.order.ticketOrder[orderIndex].addons[addOnIndex].name = this.ticketAddons[addOnIndex].name
        this.order.ticketOrder[orderIndex].addons[addOnIndex].price = this.ticketAddons[addOnIndex].price
        this.order.ticketOrder[orderIndex].addons[addOnIndex].priceString = this.ticketAddons[addOnIndex].priceString
        var cps = null
        if (this.ticketAddons[addOnIndex].cps) if (this.ticketAddons[addOnIndex].cps.item) cps = this.ticketAddons[addOnIndex].cps.item.id
        this.order.ticketOrder[orderIndex].addons[addOnIndex].cps = cps
        var foreup = null
        if (this.ticketAddons[addOnIndex].foreup) {
          foreup = {
            id: this.ticketAddons[addOnIndex].foreup.id,
            type: this.ticketAddons[addOnIndex].foreup.type,
            isPass: this.ticketAddons[addOnIndex].foreup.attributes.isPass,
            price: this.ticketAddons[addOnIndex].foreup.attributes.basePrice
          }
        }
        this.order.ticketOrder[orderIndex].addons[addOnIndex].foreup = foreup
      }
      this.calculateTotal()
    },
    calculateTotal () {
      var t = 0
      this.order.ticketOrder.forEach(function (o) {
        // add ticket price to total
        if (o.ticket.price) t = t + o.ticket.price
        // add ticket add ons to total
        if (o.addons) {
          Object.keys(o.addons).forEach(function (k) {
            if (o.addons[k].price) t = t + o.addons[k].price
          })
        }
      })
      this.order.total = t
      this.textTotal = this.currency.symbol
      if (this.order.total === 0) {
        this.textTotal += '0.00'
      } else {
        this.textTotal += t.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }
      this.order.totalString = this.textTotal
    },
    determineTicketsInCart () {
      this.order.cart = this.order.ticketOrder.reduce((counts, t) => {
        const ticketName = t.ticket.name
        if (ticketName) counts[ticketName] = counts[ticketName] ? counts[ticketName] + 1 : 1
        return counts
      }, {})
    },
    increaseTickets () {
      if (((this.numberOfTickets < this.quantities.left) || (this.quantities.left === null)) && ((this.numberOfTickets < this.quantities.max) || (this.quantities.max === null))) this.numberOfTickets++
    },
    decreaseTickets () {
      if ((this.quantities.min && this.numberOfTickets > this.quantities.min) || (!this.quantities.min && this.numberOfTickets > 1)) this.numberOfTickets--
    }
  },
  inject: {
    $validator: '$validator'
  }
}
</script>

<style scoped>
.o-ticket {
  margin-bottom: 1rem;
}
.oto-edit-screen {
  opacity: 0.3;
}
.oto-ticket-details {
  margin-bottom: 20px;
}
.oto-qty-picker-holder {
  margin-bottom: 8px;
}
.oto-qty-picker {
  max-width: 60px!important;
  text-align: center;
}
</style>
