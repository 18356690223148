import engineApi from '@/plugins/engine-api.js'
import xhr from '@/mixins/Xhr.js'
import analytics from '@/mixins/Analytics.js'
import RenderForm from '@/components/global/RenderForm'
import RenderProduct from '@/components/global/RenderProduct'
import RenderRegistration from '@/components/global/RenderRegistration'
import Share from '@/components/elements/Share'
import Editor from '@tinymce/tinymce-vue'
import FlipCountdown from 'vue2-flip-countdown'
import Vue from 'vue'
import { VueReCaptcha } from 'vue-recaptcha-v3'

export default {
  props: {
    data: {
      type: Object,
      required: true
    },
    order: {
      type: Object,
      required: true
    }
  },
  components: {
    Editor,
    RenderForm,
    RenderProduct,
    RenderRegistration,
    Share,
    FlipCountdown,
    VueReCaptcha
  },
  mixins: [xhr, analytics],
  data () {
    return {
      parentDomain: process.env.VUE_APP_IFRAME_PARENT_DOMAIN,
      editMode: false,
      account: null,
      words: null,
      imagesAvailable: null,
      theme: null,
      actions: null,
      action: null,
      confirmMessage: null,
      errorMessage: null,
      finalOptStatus: null,
      images: [],
      questions: [],
      contentReady: false,
      isLoading: false,
      isDisabled: false,
      richEdit: false,
      richContent: null,
      tinymceKey: process.env.VUE_APP_TINYMCE,
      tinyoptions: {
        forced_root_block: false,
        menubar: false,
        elementpath: false,
        paste_as_text: true,
        min_height: 400,
        browser_spellcheck: true,
        contextmenu: false,
        content_css: '/css/tinymce.css',
        body_class: 'campaignpilot',
        plugins: 'paste,link,lists',
        toolbar: 'undo redo | styleselect | bold italic underline strikethrough | alignleft aligncenter alignright | outdent indent | numlist bullist link',
        style_formats: [
          { title: 'Headings' },
          { title: 'Heading 1', format: 'h1' },
          { title: 'Heading 2', format: 'h2' },
          { title: 'Heading 3', format: 'h3' },
          { title: 'Heading 4', format: 'h4' },
          { title: 'Heading 5', format: 'h5' },
          { title: 'Heading 6', format: 'h6' },
          { title: 'Blocks' },
          { title: 'Paragraph', format: 'p' },
          { title: 'Blockquote', format: 'blockquote' },
          { title: 'Div', format: 'div' },
          { title: 'Pre', format: 'pre' }
        ]
      },
      selectedQuestion: -1,
      subtype: null,
      embedIFrame: null,
      embedButton: null,
      multiOptionDivide: ' | ' // string that separates product options when mutiple are selected
    }
  },
  computed: {
    mainTitle: function () {
      if (this.words.buckets.titles) return this.words.buckets.titles[0]
      else return null
    },
    currentTitle: function () {
      if (this.words.buckets.titles) return this.words.buckets.titles[this.words.buckets.titleIndex]
      else return null
    },
    currentHeading: function () {
      if (this.words.buckets.headings) return this.words.buckets.headings[this.words.buckets.headingIndex]
      else return null
    },
    currentDescription: function () {
      if (this.words.buckets.descriptions) return this.words.buckets.descriptions[this.words.buckets.descriptionIndex]
      else return null
    },
    currentContent: function () {
      if (this.richContent) return this.richContent
      else if (this.words.buckets.contents) return (this.words.buckets.contents[this.words.buckets.contentIndex] + '').replace(/<p><br><\/p>/g, '<br>')
      else return null
    },
    currentAction: function () {
      if (this.words.buckets.actions) return this.words.buckets.actions[this.words.buckets.actionIndex]
      else return null
    },
    buttonClass: function () {
      var c = {}
      if (this.theme) {
        c[this.theme.currentFont.class] = true
      }
      if (this.isLoading) {
        c['is-loading'] = true
      }
      return c
    },
    registration: function () {
      if (this.action === 'event') {
        if (this.actions) {
          if (this.actions.registration) {
            var r = {}
            if (this.actions.registration.capacity) r.capacity = this.actions.registration.capacity.model
            if (this.actions.registration.max) r.max = this.actions.registration.max.model
            if (this.actions.registration.min) r.min = this.actions.registration.min.model
            if (this.actions.registration.paid) r.paid = this.actions.registration.paid.model
            if (this.actions.registration.registrationDate) r.date = this.actions.registration.registrationDate.model
            return r
          }
        }
      }
      return null
    },
    tickets: function () {
      if (this.action === 'event') {
        if (this.actions) {
          if (this.actions.tickets) {
            var vm = this
            this.actions.tickets.forEach(function (ticket) {
              if (ticket.price) {
                if (typeof ticket.price === 'string') {
                  ticket.priceString = vm.currencySettings.symbol + ticket.price
                  ticket.price = parseFloat(ticket.price.replace(/,/g, ''))
                }
              }
              if (ticket.quantity) {
                if (typeof ticket.quantity === 'string') {
                  ticket.quantity = parseFloat(ticket.quantity.replace(/,/g, ''))
                }
              }
            })
            return this.actions.tickets
          } else return null
        } else return null
      } else return null
    },
    ticketQtySold: function () {
      if (this.data) {
        if (this.data.campaign) {
          if (this.data.campaign.ticketQtySold) {
            return JSON.parse(this.data.campaign.ticketQtySold)
          }
        }
      }
      return null
    },
    availableTickets: function () {
      var vm = this
      // for some reason this was the only working way to clone the ticket array, so we didn't adjust original qtys
      var workingTickets = (typeof this.tickets === 'object' && this.tickets !== null) ? JSON.parse(JSON.stringify(vm.tickets)) : null
      if (workingTickets) {
        if (workingTickets.length > 0) {
          for (let i = workingTickets.length - 1; i >= 0; i--) {
            if ('quantity' in workingTickets[i]) {
              if (workingTickets[i].quantity > 0) { // users can set ticket qty to 0 or nothing/null to not enforce ticket qty
                if (typeof vm.ticketQtySold === 'object' && vm.ticketQtySold) {
                  if (workingTickets[i].name in vm.ticketQtySold) {
                    if (workingTickets[i].quantity >= 0) {
                      workingTickets[i].quantity = workingTickets[i].quantity - vm.ticketQtySold[workingTickets[i].name] // remove the qty sold
                      if (workingTickets[i].quantity <= 0) {
                        workingTickets.splice(i, 1) // take the ticket out
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      return workingTickets
    },
    ticketAddons: function () {
      if (this.action === 'event') {
        if (this.actions) {
          if (this.actions.items) {
            var vm = this
            this.actions.items.forEach(function (addon) {
              if (addon.price) {
                if (typeof addon.price === 'string') {
                  addon.priceString = vm.currencySettings.symbol + addon.price
                  addon.price = parseFloat(addon.price.replace(/,/g, ''))
                }
              }
            })
            return this.actions.items
          } else return null
        } else return null
      } else return null
    },
    ticketPriceTable: function () {
      var vm = this
      if (vm.tickets) {
        if (vm.tickets.length > 0) {
          var th = '<table class="table is-fullwidth no-margin">'
          th = th + '<tbody>'
          th = th + '<tr>'
          th = th + '<td colspan="2"><strong>Tickets</strong></td>'
          th = th + '</tr>'
          vm.tickets.forEach(function (t) {
            th = th + '<tr>'
            th = th + '<td>' + t.name + '</td>'
            th = th + '<td>' + t.priceString + '</td>'
            th = th + '</tr>'
          })
          if (vm.ticketAddons.length > 0) th = th + '<tr><td colspan="2"><strong>Add-Ons</strong></td></tr>'
          vm.ticketAddons.forEach(function (tao) {
            th = th + '<tr>'
            th = th + '<td>' + tao.name + '</td>'
            th = th + '<td>' + tao.priceString + '</td>'
            th = th + '</tr>'
          })
          th = th + '</tbody>'
          th = th + '</table>'
          return th
        } else return null
      } else return null
    },
    iCalUrl: function () {
      if (this.data.campaign.ical) {
        return process.env.VUE_APP_ICAL_URL + '/' + this.data.campaign.ical
      } else return null
    },
    date: function () {
      var d = null
      if (this.words.variables.dateAlt) {
        d = this.words.variables.dateAlt
        if (this.words.variables.endDateAlt) {
          d = d + ' - ' + this.words.variables.endDateAlt
        }
        if (this.words.variables.time) {
          d = d + ', ' + this.words.variables.time
          if (this.words.variables.endTime) {
            d = d + ' - ' + this.words.variables.endTime
          }
        }
      }
      return d
    },
    product: function () {
      if (this.action === 'sale') {
        if (this.actions) {
          var price = null
          if (this.actions.product.price.model) {
            if (this.actions.product.price.model !== '0.00') {
              price = this.actions.product.price.model
            }
          }
          var savings = null
          if (this.actions.product.fullPrice.model) {
            var full = this.actions.product.fullPrice.model.replace(/,/g, '')
            var actual = this.actions.product.price.model.replace(/,/g, '')
            savings = (parseFloat(full) - parseFloat(actual) + 0).toFixed(2)
            savings = savings.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            if (savings === 'NaN') {
              savings = null
            }
          }
          var stock = null
          if (this.actions.product.stock.model) {
            stock = parseInt(this.actions.product.stock.model)
          }
          var cps = null
          if (this.actions.product.cps) if (this.actions.product.cps.model) if (this.actions.product.cps.model.item) cps = this.actions.product.cps.model.item.id
          var virtualGC = null
          if (this.actions.product.virtualGC) if (this.actions.product.virtualGC.model) virtualGC = true
          var foreup = null
          if (this.actions.product.foreup) {
            if (this.actions.product.foreup.model) {
              foreup = {
                id: this.actions.product.foreup.model.id,
                type: this.actions.product.foreup.model.type,
                isPass: this.actions.product.foreup.model.attributes.isPass,
                price: this.actions.product.foreup.model.attributes.basePrice
              }
            }
          }
          return {
            name: this.actions.product.name.model,
            price: price,
            fullPrice: this.actions.product.fullPrice.model,
            stock: stock,
            savings: savings,
            image: this.actions.product.imageUrl,
            cps: cps,
            foreup: foreup,
            vgc: virtualGC,
            isGallus: this.isGallus
          }
        } else {
          return null
        }
      } else {
        return null
      }
    },
    productOptions: function () {
      if (this.action === 'sale') {
        if (this.actions) {
          if (this.actions.variants) {
            var options = []
            this.actions.variants.forEach(function (variant) {
              var group = {
                name: variant.props.name.model,
                type: variant.props.display.model,
                required: variant.props.required.model,
                options: variant.options
              }
              options.push(group)
            })
            return options
          } else {
            return null
          }
        } else {
          return null
        }
      } else {
        return null
      }
    },
    shareProps: function () {
      return {
        url: window.location,
        title: this.currentTitle || 'Check this out!'
      }
    },
    todayDate: function () {
      return new Date()
    },
    openPurchaseDate: function () {
      // FOR EVENTS:
      //  Returns open date from event registration window, or right now
      if (this.action === 'event') {
        if (this.actions) {
          if (this.actions.registration) {
            if (this.actions.registration.registrationDate) {
              if (this.actions.registration.registrationDate.model) {
                var win = this.actions.registration.registrationDate.model.split(',', 2)
                if (win.length > 0) {
                  if (win[0].length > 1) {
                    return this.strToDate(win[0])
                  }
                }
              }
            }
          }
        }
      } else if (this.action === 'sale') {
        if (this.actions) {
          if (this.actions.product) {
            if (this.actions.product.saleDate) {
              if (this.actions.product.saleDate.model) {
                var saleWin = this.actions.product.saleDate.model.split(',', 2)
                if (saleWin.length > 0) {
                  if (saleWin[0].length > 1) {
                    return this.strToDate(saleWin[0]) // open date of sale window
                  }
                }
              }
            }
          }
        }
      }
      return this.todayDate
    },
    flipStartDate: function () {
      if (this.openPurchaseDate) {
        return this.openPurchaseDate.toISOString().replace(/T.*$/gi, '')
      }
      return null
    },
    flipEndDate: function () {
      if (this.closePurchaseDate) {
        return this.closePurchaseDate.toISOString().replace(/T.*$/gi, '')
      }
      return null
    },
    closePurchaseDate: function () {
      // FOR EVENTS:
      //  Returns close date from registration, end date of event, start date of event, or null
      // FOR SALES:
      //  Returns close date from sales window, or null
      if (this.action === 'event') {
        if (this.actions) {
          if (this.actions.registration) {
            if (this.actions.registration.registrationDate) {
              if (this.actions.registration.registrationDate.model) {
                var win = this.actions.registration.registrationDate.model.split(',', 2)
                var close = null
                if (win.length > 1) {
                  if (win[1].length > 1) {
                    close = this.strToDate(win[1]) // close date of registration window
                  }
                }
                if (close) {
                  return close // date from registration window
                } else {
                  if (this.words) {
                    if (this.words.variables) {
                      if (this.words.variables.endDateRaw) {
                        close = this.strToDate(this.words.variables.endDateRaw) // end date of event
                      } else if (this.words.variables.dateRaw) {
                        close = this.strToDate(this.words.variables.dateRaw) // start date of event
                      }
                    }
                  }
                  return close // date from event, or null
                }
              }
            }
          }
        }
      } else if (this.action === 'sale') {
        if (this.actions) {
          if (this.actions.product) {
            if (this.actions.product.saleDate) {
              if (this.actions.product.saleDate.model) {
                var saleWin = this.actions.product.saleDate.model.split(',', 2)
                if (saleWin.length > 1) {
                  if (saleWin[1].length > 1) {
                    return this.strToDate(saleWin[1]) // close date of sale window
                  }
                }
              }
            }
          }
        }
      }
      return null
    },
    timeToPurchase: function () {
      // returns array of [days, hours, minutes, seconds] until purchase window opens, or null
      if (this.openPurchaseDate > this.todayDate) {
        var timeRemaining = parseInt((this.openPurchaseDate - this.todayDate) / 1000)
        var days, hours, minutes, seconds
        if (timeRemaining >= 0) {
          days = parseInt(timeRemaining / 86400)
          timeRemaining = (timeRemaining % 86400)
          hours = parseInt(timeRemaining / 3600)
          timeRemaining = (timeRemaining % 3600)
          minutes = parseInt(timeRemaining / 60)
          timeRemaining = (timeRemaining % 60)
          seconds = parseInt(timeRemaining)
          return [days, hours, minutes, seconds]
        }
      }
      return null
    },
    qtyCapacity: function () {
      if (this.actions) {
        if (this.action === 'event') {
          if (this.actions.registration) {
            if (this.actions.registration.capacity) {
              if (this.actions.registration.capacity.model) {
                return parseInt(this.actions.registration.capacity.model)
              } else {
                // added ticket qty in v1.7.2 - the capacity model will be null and drop into this else
                return this.tickets.map((t) => t.quantity).reduce((total, quantity) => total + quantity, 0)
              }
            }
          }
        } else if (this.action === 'sale') {
          if (this.actions.product) {
            if (this.actions.product.stock) {
              if (this.actions.product.stock.model) {
                return parseInt(this.actions.product.stock.model)
              }
            }
          }
        }
      }
      return null
    },
    qtySold: function () {
      if (this.data) {
        if (this.data.campaign) {
          if (this.data.campaign.qtySold) {
            return parseInt(this.data.campaign.qtySold)
          }
        }
      }
      return null
    },
    qtyLeft: function () {
      if (this.qtyCapacity && this.qtySold) {
        return this.qtyCapacity - this.qtySold
      } else if (this.qtyCapacity) {
        return this.qtyCapacity
      }
      return null
    },
    qtyMin: function () {
      if (this.actions) {
        if (this.action === 'event') {
          if (this.actions.registration) {
            if (this.actions.registration.min) {
              if (this.actions.registration.min.model) {
                return parseInt(this.actions.registration.min.model)
              }
            }
          }
        }
      }
      return null
    },
    qtyMax: function () {
      if (this.actions) {
        if (this.action === 'event') {
          if (this.actions.registration) {
            if (this.actions.registration.max) {
              if (this.actions.registration.max.model) {
                return parseInt(this.actions.registration.max.model)
              }
            }
          }
        }
      }
      return null
    },
    quantities: function () {
      return {
        cap: this.qtyCapacity,
        sold: this.qtySold,
        left: this.qtyLeft,
        min: this.qtyMin,
        max: this.qtyMax
      }
    },
    triggerUnpublish: function () {
      // trigger an un-publish of this campaign if:
      // - this has a some qty and there are none left
      // - this has a close date and today is after it
      if (this.qtyLeft <= 0 && this.qtyLeft !== null) {
        return true
      } else if (this.closePurchaseDate) {
        return (this.todayDate > this.closePurchaseDate)
      }
      return false
    },
    isPurchaseOpen: function () {
      // Closes purchases if we're out of items/tickets or
      // todays date is out of sale/registration window
      var open = true
      if (this.editMode) return open // always open if we're in edit mode
      if (this.qtyLeft <= 0 && this.qtyLeft !== null) {
        open = false
      }
      if (open) {
        if (this.openPurchaseDate && this.closePurchaseDate) {
          // returns true if right now is between open and close
          open = (this.todayDate >= this.openPurchaseDate && this.todayDate <= this.closePurchaseDate)
        } else if (this.openPurchaseDate) {
          // only have open date. returns true if right now is after open
          open = (this.todayDate >= this.openPurchaseDate)
        }
      }
      return open
    },
    showActionButton: function () {
      if (this.showActionArea || this.subtype === 'engine' || this.editMode) return true
      return false
    },
    showActionArea: function () {
      if (this.isDisabled) return false
      if (this.subtype === 'paid') {
        if (this.tickets || this.product) {
          return true
        }
      }
      if (this.questions) {
        if (this.questions.length > 0) {
          return true
        }
      }
      return false
    },
    showEndCountdown: function () {
      // returns true if there is a close date within the next week and the purchase is open
      if (this.closePurchaseDate > this.todayDate) {
        var timeRemaining = parseInt((this.closePurchaseDate - this.todayDate) / 1000)
        if (timeRemaining >= 0) {
          var days = parseInt(timeRemaining / 86400)
          if (days < 8 && this.isPurchaseOpen && this.flipEndDate) {
            return true
          }
        }
      }
      return false
    },
    iframeStyle: function () {
      if (this.embedIFrame) {
        if (this.embedIFrame.finalHeight && this.embedIFrame.finalWidth) {
          var fw = this.embedIFrame.finalWidth
          if (this.embedIFrame.finalWidth === '100%') {
            fw = 'calc(100% + 15px)'
          }
          return {
            height: this.embedIFrame.finalHeight,
            width: fw,
            maxWidth: 'none'
          }
        }
      }
      return null
    },
    iframeHolderStyle: function () {
      if (this.embedIFrame) {
        if (this.embedIFrame.align) {
          return {
            width: '100%',
            textAlign: this.embedIFrame.align,
            overflow: 'hidden'
          }
        }
      }
      return null
    },
    accountLocationHTML: function () {
      if (this.account) {
        if (this.account.street && this.account.city && this.account.state && this.account.postal) {
          var l = this.account.street
          if (this.account.street2) l = l + '<br>' + this.account.street2
          l = l + '<br>' + this.account.city + ', ' + this.account.state + ' ' + this.account.postal
          return l
        }
      }
      return null
    },
    eventLocationHTML: function () {
      if (this.words) {
        if (this.words.variables) {
          if (this.words.variables.location) {
            var l = this.words.variables.location
            var i = l.indexOf(',')
            var splits = [l.slice(0, i), l.slice(i + 1)]
            l = splits[0] + '<br>' + splits[1]
            return l
          }
        }
      }
      if (this.accountLocationHTML) return this.accountLocationHTML
      return null
    },
    eventDirectionsURL: function () {
      if (this.eventLocationHTML) return 'https://maps.google.com?daddr=' + encodeURI(this.eventLocationHTML.replace('<br>', ','))
      return null
    },
    googleMaps: function () {
      if (this.words) {
        if (this.words.variables) {
          if (this.words.variables.location) return encodeURI('https://www.google.com/maps/embed/v1/place?key=' + process.env.VUE_APP_GOOGLE_MAPS_KEY + '&q=' + this.words.variables.location + '&attribution_source=' + process.env.VUE_APP_GOOGLE_MAPS_ATTRIBUTION)
        }
      }
      if (this.account) {
        if (this.account.street && this.account.city && this.account.state && this.account.postal) return encodeURI('https://www.google.com/maps/embed/v1/place?key=' + process.env.VUE_APP_GOOGLE_MAPS_KEY + '&q=' + this.account.street + ',' + this.account.city + ',' + this.account.state + ' ' + this.account.postal + '&attribution_source=' + process.env.VUE_APP_GOOGLE_MAPS_ATTRIBUTION)
      }
      return null
    },
    buttonAlign: function () {
      if (this.embedButton) {
        if (this.embedButton.align) {
          if (this.embedButton.align === 'left') {
            return { textAlign: 'left' }
          } else if (this.embedButton.align === 'right') {
            return { textAlign: 'right' }
          }
        }
      }
      return { textAlign: 'center' }
    },
    isForm: function () {
      // logic just let's us know if this is a simple form or not
      if (this.questions) {
        if (this.questions.length > 0 && this.subtype !== 'paid' && this.subtype !== 'engine') return true
      }
      return false
    },
    hasQueues: function () {
      // logic lets us know if we have pos queues this submission will be pushed to
      if (this.account.queues) {
        var vm = this
        var ids = Object.keys(this.account.queues)
        var activeQueues = ids.filter(function (q) {
          return vm.account.queues[q]
        })
        if (activeQueues) {
          if (activeQueues.length > 0) {
            return true
          }
        }
      } else if (this.account.queue_acy || this.account.queue_acy_list || this.account.queue_cps) {
        return true
      }
      return false
    },
    hasEmail: function () {
      // logic lets us know if we have an email field
      if (this.questions) {
        if (this.questions.length > 0) {
          var he = false
          this.questions.forEach(function (q) {
            if (q) {
              if (q.name) {
                var key = q.name.toLowerCase()
                if (key.includes('email')) he = true
              }
            }
          })
          return he
        }
      }
      return false
    },
    useCaptcha: function () {
      if (this.isForm) return true
      return false
    },
    useOpt: function () {
      if (this.isForm && this.hasQueues && this.hasEmail) return true
      return false
    },
    currencySettings: function () {
      // the various currency settings
      // code - use this if we need to match something
      // symbol - added to the front of all currency digits
      // notation - added to the end of all currency digits, along with an initial space
      var usd = {
        code: 'usd',
        symbol: '$',
        notation: ''
      }
      var cad = {
        code: 'cad',
        symbol: '$',
        notation: 'CAD'
      }
      var eur = {
        code: 'eur',
        symbol: '€',
        notation: ''
      }
      // the default
      var resp = usd
      // if the account has some settings, change the currency settings
      // basically we'll use whatever the payment processor is set to, otherwise
      // we use the general settings
      if (this.account) {
        if (this.account.payments) {
          if (this.account.payments.ets || this.account.payments.payroc) {
            if (this.account.currency === 'CAD') {
              resp = cad
            } else if (this.account.currency === 'EUR') {
              resp = eur
            }
          } else if (this.account.payments.paypal) {
            if (this.account.paypalCurrency === 'CAD') {
              resp = cad
            }
          } else if (this.account.payments.stripe) {
            if (this.account.stripeCurrency === 'cad') {
              resp = cad
            } else if (this.account.stripeCurrency === 'eur') {
              resp = eur
            }
          }
        } else if (this.account.currency === 'CAD') {
          resp = cad
        } else if (this.account.currency === 'EUR') {
          resp = eur
        }
      }
      return resp
    },
    isGallus: function () {
      if (this.data) if (this.data.campaign) if (this.data.campaign.gallus_offerId) return true
      return false
    }
  },
  watch: {
    triggerUnpublish (v) {
      if (v) {
        this.postUnpublish()
      }
    },
    richContent () {
      this.postRichContent()
    },
    selectedQuestion () {
      this.postSelectedQuestion()
    },
    questions () {
      // this fixes an error where we're using a .length inside the vue component html
      if (!this.questions) this.questions = []
    }
  },
  created () {
    if (this.data.editMode) {
      if (process.env.NODE_ENV === 'development') console.log('edit mode is on')
      this.editMode = true
      window.addEventListener('message', this.messageListener)
      window.addEventListener('resize', this.postHeight)
    } else {
      this.account = this.data.account
      this.imagesAvailable = this.data.campaign.images
      this.setImages()
      this.theme = this.data.campaign.theme
      this.setTheme()
      this.words = this.data.campaign.words
      this.questions = this.data.campaign.questions
      this.action = this.data.campaign.action
      this.actions = this.data.campaign.actions
      this.richContent = this.data.campaign.content
      this.subtype = this.data.campaign.actionsubtype
      this.embedIFrame = this.data.campaign.embedIFrame
      this.embedButton = this.data.campaign.embedButton
      this.contentReady = true
      if (this.data.campaign.disabled === 1) this.isDisabled = true
    }
    // this is used for embedding the landing page on the wizard confirmation step
    // we hide the scrollbars in the iframe preview
    if (this.$route.query.hidescroll) {
      document.documentElement.classList.add('oto-hidescroll')
    }
    // load Vue ReCaptcha if we need to
    if (this.useCaptcha) {
      VueReCaptcha(Vue, {
        siteKey: process.env.VUE_APP_RECAPTCHA_SITEKEY,
        loaderOptions: {
          autoHideBadge: true
        }
      })
    }
  },
  beforeDestroy () {
    if (this.editMode) {
      window.removeEventListener('message', this.messageListener, true)
      window.removeEventListener('resize', this.postHeight, true)
    }
  },
  methods: {
    messageListener (e) {
      if (e.origin !== this.parentDomain) {
        // block messages from unknown sources
        if (process.env.NODE_ENV === 'development') console.log('layout blocked message:', e.origin, this.parentDomain, e.data)
      } else if (e.data) {
        var messagePayload = JSON.parse(e.data)
        if (process.env.NODE_ENV === 'development') console.log('Message Received From Dashboard: ', messagePayload)
        if (messagePayload.imagesAvailable) {
          // set images
          this.imagesAvailable = messagePayload.imagesAvailable
          this.setImages()
        }
        if (messagePayload.theme) {
          // set theme
          this.theme = messagePayload.theme
          this.setTheme()
        }
        if (messagePayload.words) {
          this.words = messagePayload.words
        }
        if (messagePayload.questions) {
          this.questions = messagePayload.questions
        }
        if (messagePayload.account) {
          this.account = messagePayload.account
        }
        if (messagePayload.action) {
          this.action = messagePayload.action
        }
        if (messagePayload.actions) {
          this.actions = messagePayload.actions
        }
        if (messagePayload.richContent) {
          this.richContent = messagePayload.richContent
        }
        if (messagePayload.selected > -2) {
          this.selectedQuestion = messagePayload.selected
        }
        if (messagePayload.subtype) {
          this.subtype = messagePayload.subtype
        }
        if (messagePayload.embedIFrame !== undefined) {
          this.embedIFrame = messagePayload.embedIFrame
        }
        if (messagePayload.embedButton !== undefined) {
          this.embedButton = messagePayload.embedButton
        }
        if (this.theme && this.imagesAvailable && this.words && this.questions) {
          this.contentReady = true
          var vm = this
          setTimeout(function () {
            // post back some stuff to parent frame
            vm.postHeight()
            vm.postImages()
            vm.postWords()
          }, 1000)
        }
      }
    },
    setImages () {
      this.images.splice(0, 1, this.imagesAvailable.landscape[this.imagesAvailable.indexes[0]])
      this.images.splice(1, 1, this.imagesAvailable.square[this.imagesAvailable.indexes[1]])
      this.images.splice(2, 1, this.imagesAvailable.square[this.imagesAvailable.indexes[2]])
      this.images.splice(3, 1, this.imagesAvailable.square[this.imagesAvailable.indexes[3]])
    },
    setTheme () {
      var primaryRGB = this.hexToRgb(this.theme.currentColors.primary)
      this.theme.currentColors.primaryRGB = primaryRGB.r + ',' + primaryRGB.g + ',' + primaryRGB.b
      // calculate darker shades (mainly for countdown)
      this.theme.currentColors.primaryDarker = this.brightnessAdjust(this.theme.currentColors.primary, -0.1)
      this.theme.currentColors.primaryDarkerFont = this.brightnessAdjust(this.theme.currentColors.primaryFont, -0.1)
      this.theme.currentColors.secondaryDarker = this.brightnessAdjust(this.theme.currentColors.secondary, -0.1)
      this.theme.currentColors.secondaryDarkerFont = this.brightnessAdjust(this.theme.currentColors.secondaryFont, -0.1)
      // override the primary font color for the detail layout
      if (this.data.campaign.layout === 'detail') this.theme.currentColors.primaryFont = '#000000'
    },
    postRichContent () {
      var messagePayload = {
        'richContent': this.richContent
      }
      this.postMessage(messagePayload)
    },
    postSelectedQuestion () {
      var messagePayload = {
        'selectedQuestion': this.selectedQuestion
      }
      this.postMessage(messagePayload)
    },
    postHeight () {
      var messagePayload = {
        'height': this.calculateHeight()
      }
      this.postMessage(messagePayload)
    },
    postWords () {
      var messagePayload = {
        'words': this.words
      }
      this.postMessage(messagePayload)
    },
    postImages () {
      var messagePayload = {
        'imagesAvailable': this.imagesAvailable
      }
      this.postMessage(messagePayload)
    },
    postMessage (payload) {
      if (this.editMode) parent.postMessage(JSON.stringify(payload), this.parentDomain)
    },
    postUnpublish () {
      engineApi.unpublish(
        this.account.id, this.data.campaign.id, function () {}
      )
    },
    toggleRichEdit () {
      this.richEdit = !this.richEdit
    },
    calculateHeight () {
      if (document.querySelector('.page-wrapper')) {
        return document.querySelector('.page-wrapper').scrollHeight
      }
      return null
    },
    hexToRgb (hex) {
      // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
      if (hex) {
        var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i
        hex = hex.replace(shorthandRegex, function (m, r, g, b) {
          return r + r + g + g + b + b
        })
        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
        return result ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16)
        } : null
      } else {
        return null
      }
    },
    brightnessAdjust (hex, lum) {
      // hex — a hex color value such as #abc or #123456 (the hash is optional)
      // lum — the luminosity factor, i.e. -0.1 is 10% darker, 0.2 is 20% lighter, etc
      // validate hex string
      var rgb = '#'
      if (hex && lum) {
        hex = String(hex).replace(/[^0-9a-f]/gi, '')
        if (hex.length < 6) {
          hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2]
        }
        lum = lum || 0
        // convert to decimal and change luminosity
        var c
        var i
        for (i = 0; i < 3; i++) {
          c = parseInt(hex.substr(i * 2, 2), 16)
          c = Math.round(Math.min(Math.max(0, c + (c * lum)), 255)).toString(16)
          rgb += ('00' + c).substr(c.length)
        }
      } else {
        rgb += 'ffffff'
      }
      return rgb
    },
    strToDate (str) {
      // converts yyyy-mm-dd to Date
      var a = str.split('-')
      var final
      if (a) {
        if (a.length > 1) {
          final = new Date(a[0], parseInt(a[1]) - 1, a[2])
        }
      }
      return final
    },
    changePhrase (phraseToChange) {
      if (this.editMode) {
        var i = phraseToChange + 'Index'
        var b = phraseToChange + 's'
        // cycles through words avilable object
        if (this.words.buckets[i] === this.words.buckets[b].length - 1) {
          this.words.buckets[i] = 0
        } else {
          this.words.buckets[i]++
        }
        this.postWords()
      }
    },
    numberOfPhrases (phrase) {
      if (phrase) {
        var b = phrase + 's'
        if (this.words.buckets[b]) {
          return this.words.buckets[b].length
        }
      }
      return null
    },
    submitForm () {
      if (this.subtype === 'paid') {
        this.processOrder()
      } else {
        if (this.useCaptcha) { // captch only used for forms right now
          var vm = this
          vm.$recaptchaLoaded().then(() => {
            vm.$recaptcha('login').then((token) => {
              this.processForm(token)
            })
          })
        } else {
          this.processForm()
        }
      }
    },
    processOrder (recaptchaToken) {
      var vm = this
      if (vm.order) {
        // handle purchase orders and send the customer to the checkout
        vm.errorMessage = null
        var allowSubmit = true
        // dont allow submit if the total is $0.00
        if (vm.order.total <= 0) allowSubmit = false
        // double check event-specific things
        if (vm.action === 'event') {
          if (vm.order.ticketOrder) {
            if (vm.order.ticketOrder.length > 0) {
              // dont allow submit if tickets aren't selected
              if (allowSubmit) { // consistency to match next allowsubmit block
                vm.order.ticketOrder.forEach(function (ticket) {
                  if (!ticket.ticket.name) {
                    vm.errorMessage = 'Make sure to select a ticket type for each ticket in the order.'
                    vm.$scrollTo('#messageArea', 200, { easing: 'ease' })
                    allowSubmit = false
                  }
                })
              }
              // dont allow submit if we dont have the tickets
              if (allowSubmit) { // dont run if we got an error above
                vm.availableTickets.forEach(function (ticket) {
                  if ('quantity' in ticket) { // older version didn't have ticket qty, for compatibility skip then and rely on capacity
                    if (ticket.quantity > 0) { // users can set ticket qty to 0 or nothing/null to not enforce ticket qty
                      if (ticket.name in vm.order.cart) {
                        if (ticket.quantity < vm.order.cart[ticket.name]) {
                          vm.errorMessage = `We're limited on the number of ${ticket.name} tickets available. Please select a different ticket or quantity.`
                          vm.$scrollTo('#messageArea', 200, { easing: 'ease' })
                          allowSubmit = false
                        }
                      }
                    }
                  }
                })
              }
            }
          }
        }
        // double check required options
        if (vm.action === 'sale' && vm.actions) {
          if (vm.actions.variants) {
            if (vm.actions.variants.length > 0) {
              var failedRequiredOption = false
              var requiredOptions = []
              var givenOptions = []
              // figure out which option groups (variants) are required
              // and push them into an array
              vm.actions.variants.forEach(function (option, index) {
                if (option.props) if (option.props.required) if (option.props.required.model) requiredOptions.push(index)
              })
              // figure out which option groups (variants) were given
              // and push them into an array
              if (vm.order) {
                if (vm.order.selectedOptions) {
                  givenOptions = Object.keys(vm.order.selectedOptions)
                  // we only need the option group index
                  givenOptions.forEach(function (key, index) {
                    givenOptions[index] = parseInt(key.split(',')[0])
                  })
                }
              }
              // now check that all the required option groups were given
              if (requiredOptions.length > 0) {
                requiredOptions.forEach(function (requiredOption) {
                  if (!givenOptions.includes(requiredOption)) failedRequiredOption = true
                })
              }
              // looks like we're missing a required option
              if (failedRequiredOption) {
                vm.errorMessage = 'Please select the details for this product to continue your order.'
                if (vm.actions.product) {
                  if (vm.actions.product.name) {
                    vm.errorMessage = 'Please select the details for ' + vm.actions.product.name.model + ' to continue your order.'
                  }
                }
                vm.$scrollTo('#messageArea', 200, { easing: 'ease' })
                allowSubmit = false
              }
            }
          }
        }
        if (allowSubmit) {
          vm.$validator.validateAll().then(result => {
            if (result) {
              var answers = []
              var labels = []
              if (vm.action === 'event') {
                // this is an event, questions/answers are stored in the ticket order packet
                var ticketCount = 0
                var ticketFieldName = 'ticket'
                var addonFieldName = 'addons'
                if (vm.order) {
                  if (vm.order.ticketOrder) {
                    vm.order.ticketOrder.forEach(function (ticket) {
                      ticketCount++
                      if (ticketCount > 1) {
                        ticketFieldName = 'ticket_' + ticketCount
                      }
                      if (ticketCount > 1) {
                        addonFieldName = 'addons_' + ticketCount
                      }
                      var answerPacket = {}
                      var labelPacket = {}
                      // add ticket addons as submissions.
                      // this allows us to report on the ticket addons for each order
                      if (ticket.addons) {
                        answerPacket[addonFieldName] = ''
                        var options = ticket.addons
                        var isFirst = true
                        Object.keys(options).forEach(function (key) {
                          var sep = ', '
                          if (isFirst) sep = ''
                          isFirst = false
                          answerPacket[addonFieldName] = answerPacket[addonFieldName] + sep + options[key].name
                          if (options[key].price) {
                            answerPacket[addonFieldName] = answerPacket[addonFieldName] + ' (' + vm.currencySettings.symbol + options[key].price + ')'
                          }
                        })
                      }
                      // for event tickets, make these submissions.
                      // this allows us to report on the ticket type for each holder in the order
                      if (ticket.ticket) {
                        answerPacket[ticketFieldName] = ticket.ticket.name
                        if (ticket.ticket.price) {
                          answerPacket[ticketFieldName] = answerPacket[ticketFieldName] + ' (' + vm.currencySettings.symbol + ticket.ticket.price + ')'
                        }
                      }
                      if (ticket.questions) {
                        ticket.questions.forEach(function (question) {
                          if (question.type === 'grouped-field') {
                            question.questions.forEach(function (groupedQuestion) {
                              var i = question.name.replace(/_1$/g, '') // .replace(/_[0-9]{1,}$/g, '') // removes name counter
                              answerPacket[i] = groupedQuestion.model
                              labelPacket[i] = groupedQuestion.label
                            })
                          } else {
                            var i = question.name.replace(/_1$/g, '') // .replace(/_[0-9]{1,}$/g, '') // removes name counter
                            answerPacket[i] = question.model
                            labelPacket[i] = question.label
                          }
                        })
                      }
                      answers.push(answerPacket)
                      labels.push(labelPacket)
                    })
                  }
                }
              } else if (vm.action === 'sale') {
                // grab answers from the questions
                var answerPacket = {}
                var labelPacket = {}
                answerPacket['order_quantity'] = vm.order.totalToBuy
                // add product options as submissions.
                // this allows us to report on the product options for each order
                var options = vm.order.selectedOptions
                Object.keys(options).forEach(function (key) {
                  var i = (options[key].field + '').toLowerCase().replace(/\W/g, '_')
                  if (answerPacket[i]) {
                    answerPacket[i] = answerPacket[i] + vm.multiOptionDivide + options[key].name
                  } else {
                    answerPacket[i] = options[key].name
                  }
                })
                if (vm.questions) {
                  vm.questions.forEach(function (question) {
                    if (question.type === 'grouped-field') {
                      question.questions.forEach(function (groupedQuestion) {
                        if (answerPacket[groupedQuestion.name]) {
                          answerPacket[groupedQuestion.name] = answerPacket[groupedQuestion.name] + vm.multiOptionDivide + groupedQuestion.model
                        } else {
                          answerPacket[groupedQuestion.name] = groupedQuestion.model
                        }
                        labelPacket[groupedQuestion.name] = groupedQuestion.label
                      })
                    } else {
                      if (answerPacket[question.name]) {
                        answerPacket[question.name] = answerPacket[question.name] + vm.multiOptionDivide + question.model
                      } else {
                        answerPacket[question.name] = question.model
                      }
                      labelPacket[question.name] = question.label
                    }
                  })
                }
                labels.push(labelPacket)
                answers.push(answerPacket)
              }
              vm.order.response = null
              vm.order.currency = vm.currencySettings
              vm.isLoading = true
              engineApi.postForm(
                vm.account, vm.data.campaign.id, vm.data.hitsId, answers, labels, vm.order, recaptchaToken,
                function (err, response) {
                  vm.isLoading = false
                  vm.$scrollTo('#messageArea', 200, { easing: 'ease' })
                  if (err) {
                    vm.handleXhrError(err)
                    vm.errorMessage = 'Uh oh. We had a problem with your order. Our development team has been made aware of the issue.'
                  } else {
                    vm.order.response = response
                    vm.$emit('checkout', true)
                  }
                }
              )
            } else {
              vm.$scrollTo('#messageArea', 200, { easing: 'ease' })
              vm.errorMessage = 'Looks like there is a problem with your order. Check the form below for any errors and try again.'
            }
          })
        } else {
          // not allowing checkout yet
        }
      } // else no order, do nothing
    },
    processForm (recaptchaToken) {
      var vm = this
      vm.$validator.validateAll().then(result => {
        if (result) {
          var hasQuestions = false
          var answers = []
          var answerPacket = {}
          var labels = []
          var labelPacket = {}
          vm.questions.forEach(function (question) {
            hasQuestions = true
            if (question.type === 'grouped-field') {
              question.questions.forEach(function (groupedQuestion) {
                answerPacket[groupedQuestion.name] = groupedQuestion.model
                labelPacket[groupedQuestion.name] = groupedQuestion.label
              })
            } else {
              answerPacket[question.name] = question.model
              labelPacket[question.name] = question.label
            }
          })
          answers.push(answerPacket)
          labels.push(labelPacket)
          if (hasQuestions) {
            // we have questions so we need to submit them
            vm.order.response = null
            vm.isLoading = true
            engineApi.postForm(
              vm.account, vm.data.campaign.id, vm.data.hitsId, answers, labels, vm.order, recaptchaToken,
              function (err) {
                vm.isLoading = false
                vm.$scrollTo('#messageArea', 200, { easing: 'ease' })
                if (err) {
                  vm.handleXhrError(err)
                  vm.errorMessage = 'Uh oh. We had a problem with your form submission. Our development team has been made aware of the issue.'
                } else {
                  vm.confirmMessage = 'Great! Thanks for filling out our form. We\'ve received your submission successfully.'
                  if (process.env.NODE_ENV !== 'development') vm.analyticsFormSubmit(vm.currentTitle)
                  // send form confirmation email
                  // RIGHT NOW THIS DOESNT SEND A CONFIRMATION BECAUSE WERE NOT SETTING THE CUSTOMER.EMAIL
                  var textPayload = {
                    date: null,
                    location: null,
                    orderHTML: null,
                    iCalUrl: null,
                    directionsURL: null,
                    customer: {
                      name: null,
                      email: null // RIGHT NOW THIS DOESNT SEND A CONFIRMATION BECAUSE WERE NOT SETTING THE CUSTOMER.EMAIL
                    },
                    account: {
                      name: vm.account.name,
                      phone: vm.account.phone,
                      email: vm.account.email,
                      logo: vm.account.logoWide || vm.account.logoSquare
                    },
                    answers: answers,
                    labels: labels,
                    optStatus: vm.finalOptStatus
                  }
                  engineApi.updateOrder(
                    vm.account, vm.data.campaign.id, vm.data.hitsId, vm.words.variables, null, textPayload, function () {}
                  )
                }
              }
            )
          }
        } else {
          // error submitting form
          vm.$scrollTo('#messageArea', 200, { easing: 'ease' })
          vm.errorMessage = 'Looks like there is a problem with your submission. Check the form below for any errors and try again.'
        }
      })
    },
    optStatusChanged (status) {
      this.finalOptStatus = status
    }
  },
  inject: {
    $validator: '$validator'
  }
}
