<template>
  <div>
    <div v-if="type === 'payment-request' && showPaymentRequest">
      <p class="oto-checkout-header">Express Checkout</p>
      <div
        v-if="processing"
        class="oto-payment-processing notification is-light has-text-centered">
        <progress class="progress is-small is-grey"/>
        Just a moment. Your order is processing. Please keep this window open until your order is complete.
      </div>
      <div v-else>
        <div id="payment-request-button"></div>
      </div>
      <hr/>
    </div>
    <div v-else-if="type === 'form'">
      <div class="field">
        <label class="label">Card Number</label>
        <div class="control is-expanded">
          <div id="card"></div>
        </div>
      </div>
      <div class="field">
        <label class="label">Expiration Date</label>
        <div class="control is-expanded">
          <div id="expire"></div>
        </div>
      </div>
      <div class="field">
        <label class="label">CVC Number</label>
        <div class="control is-expanded">
          <div id="cvc"></div>
        </div>
      </div>
      <button
        :class="{'is-loading': processing}"
        class="hp-submit"
        @click="purchase">
        Submit Payment
      </button>
    </div>
  </div>
</template>

<script>
import VueScript2 from 'vue-script2'
import analytics from '@/mixins/Analytics.js'

export default {
  mixins: [analytics],
  props: {
    // 'payment-request' or 'form'
    // required always
    type: {
      type: String,
      required: true
    },
    // the stripe merchant account id
    // required for all types
    accountId: {
      type: String,
      required: true
    },
    // the amount to charge in nn format, i.e. passing 45 is $45.00
    // required for payment-request types
    amount: {
      type: Number,
      required: false,
      default: 0
    },
    // the currency to charge, i.e. usd or cad
    // required for payment-request types
    currency: {
      type: String,
      required: false,
      default: 'usd'
    },
    // the total label, string, to display for payment-requests
    // required for payment-request types
    totalLabel: {
      type: String,
      required: false,
      default: 'Total'
    },
    // the curstomer object to tokenize
    // required for form types
    customer: {
      type: Object,
      required: false,
      default: function () { return {} }
    },
    // boolean to tell the component to display is processing (disabled) mode
    // required for form types
    processing: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: function () {
    return {
      stripe: null,
      elements: null,
      card: null,
      expire: null,
      cvc: null,
      style: {
        base: {
          color: '#363636',
          lineHeight: '24px',
          fontFamily: '"Open Sans", sans-serif',
          fontSize: '16px',
          '::placeholder': {
            color: '#aab7c4'
          }
        },
        invalid: {
          color: '#ce1010',
          iconColor: '#ce1010'
        }
      },
      style2: {
        base: {
          color: '#363636',
          lineHeight: '24px',
          fontFamily: '"Open Sans", sans-serif',
          fontSize: '16px',
          '::placeholder': {
            color: '#ffffff'
          }
        },
        invalid: {
          color: '#ce1010',
          iconColor: '#ce1010'
        }
      },
      showPaymentRequest: true
    }
  },
  computed: {
    stripeAmount: function () {
      if (this.amount) {
        return Math.round((this.amount * 100) * 1e2) / 1e2
      } else return null
    },
    country: function () {
      if (this.currency === 'usd') return 'US'
      else if (this.currency === 'cad') return 'CA'
      else return null
    }
  },
  mounted () {
    // Load Stripe JS
    var vm = this
    VueScript2.load('https://js.stripe.com/v3/').then(function () { vm.render() })
  },
  methods: {
    render () {
      if (process.env.NODE_ENV !== 'development') this.analyticsStartPayment('stripe')
      // Load stripe and elements
      this.stripe = window.Stripe(process.env.VUE_APP_STRIPE_P_KEY, {
        stripeAccount: this.accountId
      })
      this.elements = this.stripe.elements()
      if (this.type === 'payment-request') this.configurePaymentButton()
      else if (this.type === 'form') this.configurePaymentForm()
    },
    configurePaymentButton () {
      var vm = this
      var paymentRequest = this.stripe.paymentRequest({
        country: this.country,
        currency: this.currency,
        total: {
          label: this.totalLabel,
          amount: this.stripeAmount
        },
        requestPayerName: true,
        requestPayerEmail: true,
        requestPayerPhone: true,
        requestShipping: false
      })
      var prButton = this.elements.create('paymentRequestButton', {
        paymentRequest: paymentRequest
      })
      // Check the availability of the Payment Request API, then mount
      paymentRequest.canMakePayment().then(function (result) {
        if (result) {
          prButton.mount('#payment-request-button')
        } else {
          vm.showPaymentRequest = false
        }
      })
      // Listen for the payment request response
      paymentRequest.on('token', function (ev) {
        vm.$emit('payment', ev)
        ev.complete('success')
      })
    },
    configurePaymentForm () {
      this.card = this.elements.create('cardNumber', { style: this.style })
      this.expire = this.elements.create('cardExpiry', { style: this.style })
      this.cvc = this.elements.create('cardCvc', { style: this.style2 })
      this.card.mount('#card')
      this.expire.mount('#expire')
      this.cvc.mount('#cvc')
    },
    purchase () {
      var vm = this
      vm.$emit('error', null)
      this.stripe.createToken(this.card, this.customer).then(function (result) {
        if (result.error) {
          vm.$emit('error', result.error.message)
        } else {
          vm.$emit('token', result.token)
        }
      })
    }
  },
  inject: {
    $validator: '$validator'
  }
}
</script>

<style>
.StripeElement,
.__PrivateStripeElement,
.StripeElement iframe {
  width: 100% !important;
}
.oto-payment-processing .progress {
  margin-bottom: 10px;
}
</style>
