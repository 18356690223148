export default {
  computed: {
    analyticsTotal: function () {
      if (this.order) {
        if (this.order.total) {
          return this.order.total.toString()
        }
      }
      return null
    },
    analyticsName: function () {
      if (this.data) {
        if (this.data.account) {
          return this.data.account.name
        }
      }
      return null
    },
    analyticsId: function () {
      if (this.data) {
        if (this.data.account) {
          return this.data.account.id
        }
      }
      return null
    },
    analyticsCurrency: function () {
      if (this.data) {
        if (this.data.account) {
          return this.data.account.currency
        }
      }
      return 'USD'
    }
  },
  methods: {
    analyticsStartCheckout () {
      var e = {
        'send_to': 'main',
        'AccountId': this.analyticsId,
        'AccountName': this.analyticsName,
        'value': this.analyticsTotal,
        'currency': this.analyticsCurrency,
        'checkout_step': 1,
        'transaction_id': this.confirmationNumber
      }
      window.gtag('event', 'begin_checkout', e)
    },
    analyticsStartPayment (method) {
      var e = {
        'send_to': 'main',
        'AccountId': this.analyticsId,
        'AccountName': this.analyticsName,
        'value': this.analyticsTotal,
        'currency': this.analyticsCurrency,
        'payment_type': method
      }
      window.gtag('event', 'add_payment_info', e)
    },
    analyticsCompletePurchase () {
      var e = {
        'send_to': 'main',
        'AccountId': this.analyticsId,
        'AccountName': this.analyticsName,
        'value': this.analyticsTotal,
        'currency': this.analyticsCurrency,
        'transaction_id': this.confirmationNumber
      }
      window.gtag('event', 'purchase', e)
    },
    analyticsShare (label) {
      var e = {
        'send_to': 'main',
        'AccountId': this.analyticsId,
        'AccountName': this.analyticsName,
        'method': label
      }
      window.gtag('event', 'share', e)
    },
    analyticsFormSubmit (label) {
      var e = {
        'send_to': 'main',
        'AccountId': this.analyticsId,
        'AccountName': this.analyticsName,
        'label': label
      }
      window.gtag('event', 'form_submission', e)
    }
  }
}
