<template>
  <div
    :class="fieldClasses"
    class="field">
    <label
      v-if="props.label"
      :style="label.style"
      :class="label.class"
      class="label">
      <span v-if="props.label === '&nbsp;'">&nbsp;</span>
      <span v-else>{{ props.label }}</span>
      <span
        v-if="isRequired"
        class="icon has-text-danger is-small oto-required-field">
        <i class="fas fa-star-of-life oto-fa-mini"/>
      </span>
    </label>
    <div
      v-if="props['option-multi']">
      <div
        v-for="(option, index) in optionGroupItems"
        :key="index">
        <label
          :style="label.style"
          class="checkbox">
          <input
            v-model="checkedBoxes"
            :name="props['name']"
            :value="option.value"
            :disabled="disabled"
            type="checkbox"
            @change="$emit('input', checklist)">
          {{ option.name }}
        </label>
      </div>
    </div>
    <div
      v-else
      class="control">
      <div
        v-for="(option, index) in optionGroupItems"
        :key="index">
        <label
          :style="label.style"
          class="radio">
          <input
            :name="props['name']"
            :value="option.value"
            :disabled="disabled"
            type="radio"
            @change="$emit('input', $event.target.value)">
          {{ option.name }}
        </label>
        <br>
      </div>
    </div>
  </div>
</template>

<script>
import inputs from '@/mixins/Inputs.js'

export default {
  mixins: [inputs],
  data () {
    return {
      checkedBoxes: []
    }
  },
  computed: {
    checklist: function () {
      return this.checkedBoxes.join(' | ')
    }
  }
}
</script>
