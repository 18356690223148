<template>
  <div>
    <div id="pageTop"/>
    <nav class="navbar is-spaced oto-checkout-navbar">
      <div class="navbar-holder">
        <div class="navbar-brand">
          <div class="navbar-item">
            <img
              v-if="data.account.logoWide"
              :src="data.account.logoWide"
              :alt="'Logo for ' + data.account.name">
          </div>
        </div>
      </div>
    </nav>
    <main class="oto-main">
      <div class="oto-side-background is-hidden-mobile"/>
      <div class="oto-main-container container">
        <div id="containerTop"/>
        <div
          :class="{ 'oto-duo-confirmation': step === 2 }"
          class="oto-duo">
          <div class="oto-lead">
            <!-- Left Column -->
            <div id="leftColumnTop"/>
            <!-- BILLING -->
            <div
              v-if="step === 0"
              class="oto-billing-step">
              <a
                v-if="step < 2"
                class="button is-small o-back-button"
                :class="{'disabled': processing}"
                :disabled="processing"
                @click="back()">
                <span class="icon is-small">
                  <i class="fas fa-arrow-left"/>
                </span>
                <span>Go Back</span>
              </a>
              <div id="notification-area"/>
              <div
                v-if="errorMessage"
                class="notification is-danger">
                {{ errorMessage }}
              </div>
              <PayRoc
                v-if="data.account.payments.payroc"
                :apiUsername="data.account.payrocApiUsername"
                :amount="payrocTotal"
                :totalLabel="data.account.name"
                :processing="processing"
                @response="processPayrocPayment" />
                <!-- billing form -->
              <div
                v-if="!hideBilling"
                class="oto-billing-step">
                <Stripe
                  v-if="data.account.payments.stripe && 1 === 2"
                  type="payment-request"
                  :accountId="data.account.stripeAccountId"
                  :amount="finalTotal.float"
                  :currency="data.account.stripeCurrency"
                  :totalLabel="data.account.name + ' total'"
                  :processing="processing"
                  @payment="processStripePaymentButton" />
                <p>Billing Details</p>
                <div class="billing-form">
                  <div class="field">
                    <label class="label">Full Name</label>
                    <div class="control">
                      <input
                        v-model="cust.name"
                        id="name"
                        ref="name"
                        name="name"
                        class="input"
                        type="text"
                        placeholder=""
                        v-validate="{ required: true }"
                        :class="{ 'is-danger': errors.has('name') }"/>
                    </div>
                  </div>
                  <div class="field">
                    <label class="label">Address</label>
                    <div class="control">
                      <input
                        v-model="cust.street"
                        id="street"
                        ref="street"
                        name="street"
                        class="input"
                        type="text"
                        placeholder=""
                        v-validate="{ required: true }"
                        :class="{ 'is-danger': errors.has('street') }"/>
                    </div>
                  </div>
                  <div class="field">
                    <div class="control">
                      <input
                        v-model="cust.street2"
                        id="street2"
                        ref="street2"
                        name="street2"
                        class="input"
                        type="text"
                        placeholder=""/>
                    </div>
                  </div>
                  <div class="field">
                    <label class="label">City</label>
                    <div class="control">
                      <input
                        v-model="cust.city"
                        id="city"
                        ref="city"
                        name="city"
                        class="input"
                        type="text"
                        placeholder=""
                        v-validate="{ required: true }"
                        :class="{ 'is-danger': errors.has('city') }"/>
                    </div>
                  </div>
                  <div class="field is-horizontal">
                    <div class="field-body oto-address-tablet-fix">
                      <StateSelect
                        :props="stateOptions"
                        v-model="stateOptions.model"/>
                      <div class="field half-width">
                        <label class="label">Postal Code</label>
                        <div class="control">
                          <input
                            v-model="cust.postal"
                            id="zip"
                            ref="zip"
                            name="zip"
                            class="input"
                            type="text"
                            placeholder=""
                            v-validate="{ required: true }"
                            :class="{ 'is-danger': errors.has('zip') }"/>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="field half-width">
                    <label class="label">Phone</label>
                    <div class="control">
                      <input
                        v-model="cust.phone"
                        id="phone"
                        ref="phone"
                        name="phone"
                        class="input"
                        type="tel"
                        placeholder=""
                        v-validate="{ required: true }"
                        :class="{ 'is-danger': errors.has('phone') }"/>
                    </div>
                  </div>
                  <div class="field half-width">
                    <label class="label">Email</label>
                    <div class="control">
                      <input
                        v-model="cust.email"
                        id="email"
                        ref="email"
                        name="email"
                        class="input"
                        type="email"
                        placeholder=""
                        v-validate="{ required: true }"
                        :class="{ 'is-danger': errors.has('email') }"/>
                      <div v-if="gallus" class="gallus-text-holder">
                        <div class="icon has-text-grey">
                          <i class="fas fa-exclamation-circle"/>
                        </div>
                        <div class="has-text-grey">
                          Your purchase will be delivered to the Offer Locker in our mobile app with this email address.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="payment-button-holder">
                  <div
                    v-if="data.account.payments.ets || data.account.payments.stripe"
                    class="oto-credit-card">
                    <button
                      :class="{'is-loading': processing}"
                      class="button is-success"
                      @click="gotoPayment">
                      Continue to Credit Card
                    </button>
                    <div class="secure-text-holder is-hidden-touch">
                      <div class="icon has-text-grey">
                        <i class="fas fa-lock "/>
                      </div>
                      <div class="secure-text has-text-grey">
                        All data is transmitted encrypted over a secure TLS connection
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="data.account.payments.paypal"
                    class="paypal">
                    <PayPal
                      :paypalSandbox="data.account.paypalSandbox"
                      :paypalProduction="data.account.paypalProduction"
                      :paypalAmount="finalTotal.float"
                      :paypalCurrency="data.account.paypalCurrency"
                      :paypalMode="data.account.paypalMode"
                      :customer="cust"
                      :data="data"
                      :order="order"
                      @showErrors="showErrors"
                      @confirm="gotoConfirmation"
                      @error="paymentError"/>
                  </div>
                </div>
              </div>
            </div>
            <!-- PAYMENT -->
            <div
              v-else-if="step === 1"
              class="oto-payment-step">
              <a
                v-if="step < 2"
                class="button is-small o-back-button"
                @click="back()">
                <span class="icon is-small">
                  <i class="fas fa-arrow-left"/>
                </span>
                <span>Go Back</span>
              </a>
              <p>Payment Details</p>
              <div id="notification-area"/>
              <div
                v-if="errorMessage"
                class="notification is-danger">
                {{ errorMessage }}
              </div>
              <div v-if="data.account.payments.stripe">
                <Stripe
                  type="form"
                  :accountId="data.account.stripeAccountId"
                  :customer="stripeCustomer"
                  :processing="processing"
                  @token="processStripeToken"
                  @error="stripeError" />
              </div>
              <div v-else-if="data.account.payments.ets">
                <label class="label">Cards Accepted</label>
                <div class="hp-cards">
                  <img
                    v-if="data.account.cards.visa"
                    class="hp-cards-icons"
                    src="https://cdn.rawgit.com/etsms/payment-icons/master/svg/flat/visa.svg"
                    alt="VISA"/>
                  <img
                    v-if="data.account.cards.mastercard"
                    class="hp-cards-icons"
                    src="https://cdn.rawgit.com/etsms/payment-icons/master/svg/flat/mastercard.svg"
                    alt="Master Card"/>
                  <img
                    v-if="data.account.cards.discover"
                    class="hp-cards-icons"
                    src="https://cdn.rawgit.com/etsms/payment-icons/master/svg/flat/discover.svg"
                    alt="Discover"/>
                  <img
                    v-if="data.account.cards.amex"
                    class="hp-cards-icons"
                    src="https://cdn.rawgit.com/etsms/payment-icons/master/svg/flat/amex.svg"
                    alt="AMEX"/>
                  <img
                    v-if="data.account.cards.jcb"
                    class="hp-cards-icons"
                    src="https://cdn.rawgit.com/etsms/payment-icons/master/svg/flat/jcb.svg"
                    alt="JCB"/>
                </div>
                <ETS
                  :etsKey="data.account.etsKey"
                  :cards="data.account.cards"
                  :etsAmount="finalTotal.float"
                  :custName="cust.name"
                  :custStreet="cust.street"
                  :custZip="cust.postal"
                  :data="data"
                  :order="order"
                  @confirm="gotoConfirmation"
                  @error="paymentError"/>
              </div>
            </div>
            <!-- CONFIRMATION -->
            <div
              v-else-if="step === 2"
              class="oto-confirmation-step">
              <div
                v-if="order.product"
                class="o-heading no-print">
                Thanks for your order! Your purchase is complete.
              </div>
              <div
                v-else-if="order.ticketOrder"
                class="o-heading no-print">
                Thanks for your order! Your registration is complete.
              </div>
              <div
                v-if="orderHTML"
                class="o-html">
                <p>Your Order Details</p>
                <p>Confirmation: <span class="font-ptmono">{{ confirmationNumber }}</span></p>
                <div class="product-order-summary product-order-summary-confirmation">
                  <div
                    v-html="orderHTML"
                    class="o-holder"/>
                </div>
                <p
                  v-if="orderPaymentConfirmation"
                  class="oto-payment-confirmation-text">
                  {{ orderPaymentConfirmation }}
                </p>
              </div>
              <div v-if="customConfirmation" v-html="customConfirmation" />
              <div class="o-buttons">
                <div class="o-print-holder no-print">
                  <a
                    class="button is-small"
                    @click="printPage()">
                    <span class="icon is-small">
                      <i class="fas fa-print"/>
                    </span>
                    <span>Print</span>
                  </a>
                </div>
                <div
                  v-if="data.campaign.ical"
                  class="o-ical-holder no-print">
                  <a
                    :href="iCalUrl"
                    class="button is-small">
                    <span class="icon is-small">
                      <i class="fas fa-calendar-plus"/>
                    </span>
                    <span>Add to Calendar</span>
                  </a>
                </div>
              </div>
              <div class="o-share-holder no-print">
                <div class="o-share-tagline">
                  <span v-if="order.product">Congrats on your new purchase! Share the fun:</span>
                  <span v-else-if="order.ticketOrder">Events are more fun with friends! Share our event:</span>
                </div>
                <Share :props="shareProps"/>
              </div>
            </div>
          </div>
          <!-- RIGHT SIDE / SUMMARY -->
          <aside class="oto-side">
            <!-- PRODUCT SUMMARY -->
            <div
              v-if="order.product"
              class="oto-o">
              <!-- heading -->
              <div
                v-if="step !== 2"
                class="o-heading">Order Summary</div>
              <!-- location -->
              <div
                v-if="step === 2"
                class="o-location-holder">
                <p>{{ data.account.name }}</p>
                <p
                  v-if="location"
                  v-html="location"/>
              </div>
              <!-- contact -->
              <div
                v-if="step === 2"
                class="o-contact-holder">
                <p>For order questions, contact us at:</p>
                <p>
                  <a :href="'mailto:' + data.account.email">{{ data.account.email }}</a>
                  <br>
                  {{ data.account.phone }}
                </p>
              </div>
              <!-- order summary -->
              <div
                v-if="orderHTML && step !== 2"
                class="product-order-summary">
                <div
                  v-html="orderHTML"
                  class="o-holder"/>
              </div>
            </div>
            <!-- EVENT SUMMARY -->
            <div
              v-else-if="order.ticketOrder"
              class="oto-o">
              <!-- heading -->
              <div
                v-if="step === 2"
                class="o-heading no-print">See You Soon!</div>
              <div
                v-else
                class="o-heading">Order Summary</div>
              <!-- title -->
              <div
                v-if="data.campaign.words.variables.title"
                class="o-title">
                {{ data.campaign.words.variables.title }}
              </div>
              <!-- date -->
              <div
                v-if="date"
                class="o-date">
                <p>Event Date:</p>
                <p>{{ date }}</p>
              </div>
              <!-- location -->
              <div
                v-if="step === 2 && location"
                class="o-location-holder">
                <p>Event Location:</p>
                <p>{{ data.account.name }}</p>
                <span
                  v-html="location"
                  class="o-location"/>
                <div
                  v-if="directionsURL"
                  class="o-directions">
                  <a
                    :href="directionsURL"
                    class="no-print"
                    target="_blank">Get Directions</a>
                </div>
              </div>
              <!-- contact -->
              <div
                v-if="step === 2"
                class="o-contact-holder">
                <p>For event questions, contact us at:</p>
                <p>
                  <a :href="'mailto:' + data.account.email">{{ data.account.email }}</a>
                  <br>
                  {{ data.account.phone }}
                </p>
              </div>
              <!-- order summary -->
              <div
                v-if="orderHTML && step !== 2"
                class="product-order-summary">
                <div
                  v-html="orderHTML"
                  class="o-holder"/>
              </div>
            </div>
          </aside>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import engineApi from '@/plugins/engine-api.js'
import StateSelect from '@/components/elements/StateSelect'
import ETS from '@/components/payments/ETS'
import PayPal from '@/components/payments/PayPal'
import Stripe from '@/components/payments/Stripe'
import PayRoc from '@/components/payments/PayRoc'
import Share from '@/components/elements/Share'
import analytics from '@/mixins/Analytics.js'

export default {
  mixins: [analytics],
  components: {
    StateSelect,
    ETS,
    PayPal,
    Stripe,
    PayRoc,
    Share
  },
  props: {
    data: {
      type: Object,
      required: true
    },
    order: {
      type: Object,
      required: true
    },
    words: {
      type: Object,
      required: false
    }
  },
  data: function () {
    // this.order.total "number" this is the subtotal
    // this.order.totalString "string" this is the subtotal in string form with a $/€
    // this.finalTotal.float "number" this is the total with tax
    // this.finalTotal.string "string" this is the total with tax in string form with a $/€
    // this.taxTotal.float "number" this is the total charge for tax
    // this.taxTotal.string "string" this is the total charge for tax in string form with a $
    return {
      step: 0, // 0 = details, 1 = payment, 2 = confirm
      processing: false,
      theme: this.data.campaign.theme,
      errorMessage: null,
      cust: {
        name: null,
        street: null,
        street2: null,
        city: null,
        postal: null,
        phone: null,
        email: null,
        state: null,
        country: null
      },
      stateOptions: {
        label: 'State',
        name: 'state',
        ref: 'state',
        fieldClass: 'half-width',
        class: 'is-fullwidth',
        validate: { required: true },
        model: null,
        hideRequired: true,
        hideErrors: true
      },
      paymentRequestCustomer: null
    }
  },
  computed: {
    iCalUrl: function () {
      if (this.data.campaign.ical) {
        return process.env.VUE_APP_ICAL_URL + '/' + this.data.campaign.ical
      } else return null
    },
    date: function () {
      var d = null
      if (this.data.campaign.words.variables.dateAlt) {
        d = this.data.campaign.words.variables.dateAlt
        if (this.data.campaign.words.variables.endDateAlt) {
          d = d + ' - ' + this.data.campaign.words.variables.endDateAlt
        }
        if (this.data.campaign.words.variables.time) {
          d = d + ', ' + this.data.campaign.words.variables.time
          if (this.data.campaign.words.variables.endTime) {
            d = d + ' - ' + this.data.campaign.words.variables.endTime
          }
        }
      }
      return d
    },
    location: function () {
      // return the location of the event otherwise return the account location if there
      var l
      if (this.data.campaign) {
        if (this.data.campaign.words) {
          if (this.data.campaign.words.variables) {
            if (this.data.campaign.words.variables.location) {
              l = this.data.campaign.words.variables.location
              var i = l.indexOf(',')
              var splits = [l.slice(0, i), l.slice(i + 1)]
              l = splits[0] + '<br>' + splits[1]
              return l
            }
          }
        }
      }
      if (this.data.account) {
        if (this.data.account.street && this.data.account.city && this.data.account.state && this.data.account.postal) {
          l = this.data.account.street
          if (this.data.account.street2) l = l + '<br>' + this.data.account.street2
          l = l + '<br>' + this.data.account.city + ', ' + this.data.account.state + ' ' + this.data.account.postal
          return l
        }
      }
      return null
    },
    directionsURL: function () {
      if (this.location) return 'https://maps.google.com?daddr=' + encodeURI(this.location.replace('<br>', ','))
      return null
    },
    orderHTML: function () {
      var oh = ''
      var vm = this
      if (vm.order.product) {
        oh = oh + '<div class="o-row">'
        oh = oh + '<div class="o-cell">' + vm.order.totalToBuy + ' x ' + vm.order.product.name + '</div>'
        oh = oh + '<div class="o-cell o-price">'
        if (vm.order.product.price) oh = oh + vm.order.currency.symbol + vm.order.product.price
        oh = oh + '</div>'
        oh = oh + '</div>'
        Object.keys(vm.order.selectedOptions).forEach(function (ao) {
          oh = oh + '<div class="o-row">'
          oh = oh + '<div class="o-cell oto-addon-name">' + vm.order.selectedOptions[ao].name + '</div>'
          oh = oh + '<div class="o-cell o-price">'
          if (vm.order.selectedOptions[ao].price > 0) {
            oh = oh + vm.order.currency.symbol + parseFloat(vm.order.selectedOptions[ao].price).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
          } else if (vm.order.selectedOptions[ao].price < 0) {
            oh = oh + '<span class="o-subtract">- ' + vm.order.currency.symbol + Math.abs(parseFloat(vm.order.selectedOptions[ao].price)).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') + '</span>'
          }
          oh = oh + '</div>'
          oh = oh + '</div>'
        })
        if (vm.order.giftRecipient) {
          if (vm.order.giftRecipient.name || vm.order.giftRecipient.email) {
            oh = oh + '<div class="o-row">'
            oh = oh + '<div class="o-cell oto-addon-name">Recipient: '
            if (vm.order.giftRecipient.name && vm.order.giftRecipient.email) {
              oh = oh + vm.order.giftRecipient.name + ' (' + vm.order.giftRecipient.email + ')'
            } else if (vm.order.giftRecipient.name) {
              oh = oh + vm.order.giftRecipient.name
            } else if (vm.order.giftRecipient.email) {
              oh = oh + vm.order.giftRecipient.email
            }
            oh = oh + '</div>'
            oh = oh + '<div class="o-cell o-price"></div>'
            oh = oh + '</div>'
          }
        }
        oh = oh + '<div class="o-row o-empty"><div class="o-cell o-empty"></div><div class="o-cell o-empty"></div></div>'
        if (vm.tax) {
          oh = oh + '<div class="o-row">'
          oh = oh + '<div class="o-cell subtotal-row">Subtotal</div>'
          oh = oh + '<div class="o-cell o-price subtotal-row">' + vm.order.currency.symbol + parseFloat(vm.order.total).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') + '</div>'
          oh = oh + '</div>'
          oh = oh + '<div class="o-row">'
          oh = oh + '<div class="o-cell tax-row">Tax <span class="oto-tax-rate">(' + vm.tax + '%)</span></div>'
          oh = oh + '<div class="o-cell o-price tax-row">'
          oh = oh + vm.taxTotal.string
          oh = oh + '</div>'
          oh = oh + '</div>'
        }
        oh = oh + '<div class="o-row">'
        oh = oh + '<div class="o-cell total-row">Total'
        if (vm.step === 2) oh = oh + ' Charged'
        oh = oh + '</div>'
        oh = oh + '<div class="o-cell o-price total-row">' + vm.finalTotal.string + ' ' + vm.order.currency.notation + '</div>'
        oh = oh + '</div>'
      } else if (vm.order.ticketOrder) {
        oh = oh + '<div class="o-rows">'
        this.order.ticketOrder.forEach(function (o) {
          oh = oh + '<div class="o-row">'
          oh = oh + '<div class="o-cell">' + o.ticket.name + '</div>'
          oh = oh + '<div class="o-cell o-price">' + o.ticket.priceString + '</div>'
          oh = oh + '</div>'
          Object.keys(o.addons).forEach(function (ao) {
            oh = oh + '<div class="o-row">'
            oh = oh + '<div class="o-cell oto-addon-name">' + o.addons[ao].name + '</div>'
            oh = oh + '<div class="o-cell o-price">'
            if (o.addons[ao].price) {
              oh = oh + o.addons[ao].priceString
            }
            oh = oh + '</div>'
            oh = oh + '</div>'
          })
        })
        oh = oh + '<div class="o-row o-empty"><div class="o-cell o-empty"></div><div class="o-cell o-empty"></div></div>'
        if (vm.tax) {
          oh = oh + '<div class="o-row">'
          oh = oh + '<div class="o-cell subtotal-row">Subtotal</div>'
          oh = oh + '<div class="o-cell o-price subtotal-row">' + vm.order.currency.symbol + parseFloat(vm.order.total).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') + '</div>'
          oh = oh + '</div>'
          oh = oh + '<div class="o-row">'
          oh = oh + '<div class="o-cell tax-row">Tax <span class="oto-tax-rate">(' + vm.tax + '%)</span></div>'
          oh = oh + '<div class="o-cell o-price tax-row">'
          oh = oh + vm.taxTotal.string
          oh = oh + '</div>'
          oh = oh + '</div>'
        }
        oh = oh + '<div class="o-row">'
        oh = oh + '<div class="o-cell total-row">Total'
        if (vm.step === 2) oh = oh + ' Charged'
        oh = oh + '</div>'
        oh = oh + '<div class="o-cell o-price total-row">' + vm.finalTotal.string + ' ' + vm.order.currency.notation + '</div>'
        oh = oh + '</div>'
        oh = oh + '</div>'
      }
      return oh
    },
    confirmationNumber: function () {
      var c = null
      if (this.order) {
        if (this.order.response) {
          c = this.order.response.s
        }
      }
      return c
    },
    orderPaymentConfirmation: function () {
      var opc = null
      if (this.order) {
        if (this.order.payment) {
          if (this.order.payment.engineType === 'ets') {
            opc = 'Approval code ' + this.order.payment.transaction_approval_code
            if (this.order.payment.instrument_type) opc = opc + ' confirmed with ' + this.order.payment.instrument_type
            else if (this.order.payment.instrument_last_four) opc = opc + ' confirmed with card'
            if (this.order.payment.instrument_last_four) opc = opc + ' ending in ' + this.order.payment.instrument_last_four
          } else if (this.order.payment.engineType === 'paypal') {
            opc = 'Paid via PayPal order id ' + this.order.payment.orderID
          } else if (this.order.payment.engineType === 'stripe') {
            opc = 'Paid via Stripe'
          }
        }
      }
      return opc
    },
    shareProps: function () {
      return {
        url: window.location,
        title: document.title,
        inline: true
      }
    },
    stripeCustomer: function () {
      // returns an object of a customer formatted for Stripe
      var c = {}
      if (this.cust.name) c.name = this.cust.name
      if (this.cust.postal) c.address_zip = this.cust.postal
      if (this.cust.country) c.address_country = this.cust.country
      if (this.data.account.stripeCurrency) c.currency = this.data.account.stripeCurrency
      return c
    },
    hideBilling: function () {
      // certain payment processors (payroc) need the billing page, but need the fields hidden
      // use this value as a flag to determine to hide billing details or not
      if (this.data.account.payments.payroc) return true
      return false
    },
    payrocTotal: function () {
      // payroc likes the total in cents, make a special total just for them
      if (this.finalTotal.float) return Math.round((this.finalTotal.float * 100) * 1e2) / 1e2
      else return 0
    },
    tax: function () {
      // if there's a chargable tax, return the percent in a string (i.e. "6.50")
      // otherwise return as null
      // can be used as a flag to charge tax or not
      if (this.data.campaign) {
        if (this.data.campaign.actions) {
          if (this.data.campaign.actions.registration) {
            if (this.data.campaign.actions.registration.tax) {
              if (this.data.campaign.actions.registration.tax.charge) {
                return this.data.campaign.actions.registration.tax.model
              }
            }
          } else if (this.data.campaign.actions.product) {
            if (this.data.campaign.actions.product.tax) {
              if (this.data.campaign.actions.product.tax.charge) {
                return this.data.campaign.actions.product.tax.model
              }
            }
          }
        }
      }
      return null
    },
    taxTotal: function () {
      // returns an object of the tax being charged
      // (i.e. { string: '$10.20', float: 10.20 })
      var tt = { string: this.order.currency.symbol + '0.00', float: 0 }
      if (this.tax) {
        var taxRate = parseFloat(this.tax) / 100.0
        tt.float = Math.round((this.order.total * taxRate) * 1e2) / 1e2
        tt.string = this.order.currency.symbol + tt.float.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }
      return tt
    },
    finalTotal: function () {
      // returns an object of the final total (this.order.total + tax) being charged
      // (i.e. { string: '$53.56', float: 53.56 })
      var ft = { string: this.order.currency.symbol + '0.00', float: 0 }
      if (this.order.total) {
        ft.float = Math.round((this.order.total + this.taxTotal.float) * 1e2) / 1e2
        ft.string = this.order.currency.symbol + ft.float.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }
      return ft
    },
    cps: function () {
      var ids = []
      var qtys = []
      if (this.order) {
        if (this.order.ticketOrder) {
          this.order.ticketOrder.forEach(function (to) {
            if (to.ticket) {
              if (to.ticket.cps) {
                ids.push(to.ticket.cps)
                qtys.push(1)
              }
            }
            if (to.addons) {
              Object.keys(to.addons).forEach(function (sto) {
                if (to.addons[sto].cps) {
                  ids.push(to.addons[sto].cps)
                  qtys.push(1)
                }
              })
            }
          })
        }
        if (this.order.product && this.order.totalToBuy) {
          if (this.order.product.cps) {
            ids.push(this.order.product.cps)
            qtys.push(this.order.totalToBuy)
          }
        }
        if (this.order.selectedOptions) {
          var vm = this
          Object.keys(this.order.selectedOptions).forEach(function (spo) {
            if (vm.order.selectedOptions[spo].cps) {
              ids.push(vm.order.selectedOptions[spo].cps)
              qtys.push(vm.order.totalToBuy)
            }
          })
        }
      }
      if (ids.length > 0) return { ids: ids.join(','), qtys: qtys.join(',') }
      return null
    },
    gallus: function () {
      if (this.data) {
        if (this.data.campaign) {
          if (this.data.campaign.gallus_offerId) {
            return {
              id: this.data.campaign.gallus_offerId
            }
          }
        }
      }
      return null
    },
    foreup: function () {
      var ids = []
      var qtys = []
      if (this.order) {
        if (this.order.ticketOrder) {
          this.order.ticketOrder.forEach(function (to) {
            if (to.ticket) {
              if (to.ticket.foreup) {
                ids.push(JSON.stringify(to.ticket.foreup))
                qtys.push(1)
              }
            }
            if (to.addons) {
              Object.keys(to.addons).forEach(function (sto) {
                if (to.addons[sto].foreup) {
                  ids.push(JSON.stringify(to.addons[sto].foreup))
                  qtys.push(1)
                }
              })
            }
          })
        }
        if (this.order.product && this.order.totalToBuy) {
          if (this.order.product.foreup) {
            ids.push(JSON.stringify(this.order.product.foreup))
            qtys.push(this.order.totalToBuy)
          }
        }
        if (this.order.selectedOptions) {
          var vm = this
          Object.keys(this.order.selectedOptions).forEach(function (spo) {
            if (vm.order.selectedOptions[spo].foreup) {
              ids.push(JSON.stringify(vm.order.selectedOptions[spo].foreup))
              qtys.push(vm.order.totalToBuy)
            }
          })
        }
      }
      if (ids.length > 0) return { ids: ids.join(','), qtys: qtys.join(',') }
      return null
    },
    customConfirmation: function () {
      if (this.data.campaign) if (this.data.campaign.actions) if (this.data.campaign.actions.confirmation) if (this.data.campaign.actions.confirmation.model) return this.data.campaign.actions.confirmation.model
      return null
    }
  },
  watch: {
    'stateOptions.model' (val) {
      this.cust.state = val
      if (val === 'AB' ||
          val === 'BC' ||
          val === 'MB' ||
          val === 'NB' ||
          val === 'NL' ||
          val === 'NT' ||
          val === 'NS' ||
          val === 'NU' ||
          val === 'ON' ||
          val === 'PE' ||
          val === 'QC' ||
          val === 'SK' ||
          val === 'YT') {
        this.cust.country = 'CA'
      } else {
        this.cust.country = 'US'
      }
    },
    errorMessage (val) {
      this.$scrollTo('#notification-area', 200, { easing: 'ease' })
    }
  },
  mounted () {
    this.$scrollTo('#pageTop', 200, { easing: 'ease' })
    if (process.env.NODE_ENV !== 'development') this.analyticsStartCheckout()
  },
  methods: {
    gotoPayment () {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.step = 1
          this.errorMessage = null
        } else {
          this.errorMessage = 'It looks like there is an issue with the data you provided. Please check the form below and try again.'
        }
      })
    },
    gotoConfirmation () {
      this.step = 2
      this.updateOrder()
      this.$scrollTo('#pageTop', 200, { easing: 'ease' })
      if (process.env.NODE_ENV !== 'development') this.analyticsCompletePurchase()
    },
    back () {
      if (this.step === 0) this.$emit('toggle', true)
      if (this.step === 1) this.step = 0
    },
    showErrors () {
      this.errorMessage = 'It looks like there is an issue with the data you provided. Please check the form below and try again.'
    },
    printPage () {
      window.print()
    },
    updateOrder () {
      var vm = this
      var textPayload = {
        date: vm.date,
        location: vm.location,
        orderHTML: vm.orderHTML,
        iCalUrl: vm.iCalUrl,
        directionsURL: vm.directionsURL,
        confirmation: vm.customConfirmation,
        account: {
          name: vm.data.account.name,
          phone: vm.data.account.phone,
          email: vm.data.account.email,
          logo: vm.data.account.logoWide || vm.data.account.logoSquare
        }
      }
      // add in the club prophet info
      vm.order.cps = vm.cps
      // add in the gallus golf info
      vm.order.gallus = vm.gallus
      // add in the foreup info
      vm.order.foreup = vm.foreup
      // add in the tax and actual totals
      vm.order.taxTotal = (vm.taxTotal) ? vm.taxTotal.float : null
      vm.order.taxRate = (vm.tax) ? (parseFloat(vm.tax) / 100.0) : null
      vm.order.finalTotal = (vm.finalTotal) ? vm.finalTotal.float : null
      // add in the customer record
      if (vm.paymentRequestCustomer) textPayload.customer = vm.paymentRequestCustomer
      else textPayload.customer = vm.cust
      // update the order
      engineApi.updateOrder(vm.data.account, vm.data.campaign.id, vm.data.hitsId, vm.words, vm.order, textPayload, function () {})
    },
    paymentError (err) {
      this.errorMessage = 'There was an error with your payment. Please check your details and try again.'
      if (err) {
        this.errorMessage = this.errorMessage + ' ' + err
      }
      if (this.step === 1) this.back() // go back to billing details
    },
    processStripeToken (token) {
      var vm = this
      vm.errorMessage = null
      vm.processing = true
      var desc = vm.data.campaign.words.variables.title + ' - ' + vm.order.response.s
      engineApi.processPayment('stripe', vm.data.account.id, desc, vm.order, vm.finalTotal, vm.taxTotal, token, function (error, response) {
        vm.processing = false
        if (error) {
          if (error === 'soldout') {
            vm.step = 0
            vm.errorMessage = 'We\'re sorry but the quantity you are trying to purchase is no longer available. Your card has not been charged.'
          } else {
            vm.errorMessage = error
          }
        } else {
          vm.order.payment = response
          vm.order.payment.engineType = 'stripe'
          vm.gotoConfirmation()
        }
      })
    },
    processStripePaymentButton (button) {
      this.paymentRequestCustomer = null
      if (button) {
        if (button.token) {
          if (button.token.card) {
            this.paymentRequestCustomer = {}
            this.paymentRequestCustomer.name = button.payerName
            this.paymentRequestCustomer.email = button.payerEmail
            this.paymentRequestCustomer.phone = button.payerPhone
            this.paymentRequestCustomer.street = button.token.card.address_line1
            this.paymentRequestCustomer.street2 = button.token.card.address_line2
            this.paymentRequestCustomer.city = button.token.card.city
            this.paymentRequestCustomer.state = button.token.card.state
            this.paymentRequestCustomer.postal = button.token.card.zip
            this.paymentRequestCustomer.country = button.token.card.country
            // attempt to process the token
            this.processStripeToken(button.token)
          }
        }
      }
    },
    stripeError (error) {
      this.errorMessage = error
    },
    processPayrocPayment (response) {
      this.paymentRequestCustomer = null
      if (response) {
        if (response.token) {
          this.paymentRequestCustomer = {}
          this.paymentRequestCustomer.name = response.billingName
          this.paymentRequestCustomer.email = response.email
          this.paymentRequestCustomer.phone = null
          this.paymentRequestCustomer.street = response.billingAddressLine1
          this.paymentRequestCustomer.street2 = response.billingAddressLine2
          this.paymentRequestCustomer.city = response.billingAddressCity
          this.paymentRequestCustomer.state = response.billingAddressState
          this.paymentRequestCustomer.postal = response.billingAddressPostalCode
          this.paymentRequestCustomer.country = null
          // attempt to process the token
          this.processPayrocToken(response.token)
        }
      }
    },
    processPayrocToken (token) {
      var vm = this
      vm.errorMessage = null
      vm.processing = true
      engineApi.processPayment('payroc', vm.data.account.id, null, vm.order, vm.finalTotal, vm.taxTotal, token, function (error, response) {
        vm.processing = false
        if (error) {
          if (error === 'soldout') {
            vm.step = 0
            vm.errorMessage = 'We\'re sorry but the quantity you are trying to purchase is no longer available. Your card has not been charged.'
          } else {
            vm.errorMessage = error
          }
        } else {
          vm.order.payment = response
          vm.order.payment.engineType = 'payroc'
          vm.gotoConfirmation()
        }
      })
    }
  },
  inject: {
    $validator: '$validator'
  }
}
</script>
