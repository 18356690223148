<template>
  <div v-if="props.inline">
    <div class="buttons are-small">
      <a
        class="button is-rounded email oto-share-button"
        :href="mailtoURL"
        @click="share('email')">
        <span class="icon is-medium">
          <i class="fas fa-envelope fa-lg"/>
        </span>
      </a>
      <a
        class="button is-rounded facebook oto-share-button"
        target="_blank"
        :href="facebookURL"
        @click="share('facebook')">
        <span class="icon is-medium">
          <i class="fab fa-facebook fa-lg"/>
        </span>
      </a>
      <a
        class="button is-rounded twitter oto-share-button"
        target="_blank"
        :href="twitterURL"
        @click="share('email')">
        <span class="icon is-medium">
          <i class="fab fa-twitter fa-lg"/>
        </span>
      </a>
    </div>
  </div>
  <div v-else>
    <click-outside :handler="clickedOutside">
      <div
        :class="{ 'is-active': state }"
        class="dropdown is-right">
        <div class="dropdown-trigger">
          <a
            v-if="!state"
            class="has-text-grey-dark oto-share-link"
            @click="toggle">
            <span class="icon">
              <i class="fas fa-share-alt"/>
            </span>
            <span class="is-uppercase has-text-weight-bold is-size-7 oto-uppercase-letter-spacing">
              Share
            </span>
          </a>
        </div>
        <div class="dropdown-menu oto-share-drop-down">
          <div class="dropdown-content is-paddingless">
            <div class="dropdown-item is-paddingless">
              <div class="buttons are-small">
                <transition
                  name="bounce"
                  enter-active-class="fadeInDown"
                  leave-active-class="fadeOut">
                  <a
                    v-if="state"
                    class="button is-rounded email oto-share-button"
                    :href="mailtoURL"
                    @click="share('email', true)">
                    <span class="icon is-medium">
                      <i class="fas fa-envelope fa-lg"/>
                    </span>
                  </a>
                </transition>
                <transition
                  name="bounce"
                  enter-active-class="fadeInDown"
                  leave-active-class="fadeOut">
                  <a
                    v-if="state"
                    class="button is-rounded facebook oto-share-button"
                    target="_blank"
                    :href="facebookURL"
                    @click="share('facebook', true)">
                    <span class="icon is-medium">
                      <i class="fab fa-facebook fa-lg"/>
                    </span>
                  </a>
                </transition>
                <transition
                  name="bounce"
                  enter-active-class="fadeInDown"
                  leave-active-class="fadeOut">
                  <a
                    v-if="state"
                    class="button is-rounded twitter oto-share-button"
                    target="_blank"
                    :href="twitterURL"
                    @click="share('twitter', true)">
                    <span class="icon is-medium">
                      <i class="fab fa-twitter fa-lg"/>
                    </span>
                  </a>
                </transition>
              </div>
            </div>
          </div>
        </div>
      </div>
    </click-outside>
  </div>
</template>

<script>
import ClickOutside from '@/plugins/onclick-outside'
import analytics from '@/mixins/Analytics.js'
export default {
  mixins: [analytics],
  components: {
    ClickOutside
  },
  props: {
    props: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      state: false
    }
  },
  computed: {
    facebookURL: function () {
      if (this.props.url && this.props.title) return 'https://www.facebook.com/share.php?u=' + this.props.url + '&title=' + this.props.title
      else return null
    },
    twitterURL: function () {
      if (this.props.url && this.props.title) return 'https://twitter.com/intent/tweet?status=' + this.props.title + ' ' + this.props.url
      else return null
    },
    mailtoURL: function () {
      if (this.props.url && this.props.title) return 'mailto:?subject=' + this.props.title + '&body=' + this.props.url
      else return null
    }
  },
  mounted: function () {

  },
  methods: {
    toggle () {
      this.state = !this.state
    },
    clickedOutside () {
      if (this.state) {
        this.state = false
      }
    },
    share (type, toggle) {
      if (toggle) this.toggle()
      if (process.env.NODE_ENV !== 'development') this.analyticsShare(type)
    }
  }
}
</script>

<style scoped>
.oto-share-button {
  min-width: 47px!important;
}
.oto-share-drop-down {
  top: -15px;
}
.oto-share-drop-down .dropdown-content {
  float: right;
  box-shadow: none!important;
  background-color: transparent!important;
}
.button.is-rounded {
  border-radius: 10em!important;
  padding-left: 1.1em!important;
  padding-right: 1.1em!important;
  margin-top: 0px!important;
  border-color: #fff!important;
  opacity: 0.9;
}
.button.is-rounded:hover {
  opacity: 1;
}
.email {
  background-color: #24C980;
  color: #fff;
}
.facebook {
  background-color: #3b5998;
  color: #fff;
}
.twitter {
  background-color: #55acee;
  color: #fff;
}
</style>
