import { render, staticRenderFns } from "./RenderForm.vue?vue&type=template&id=5df66352&scoped=true&"
import script from "./RenderForm.vue?vue&type=script&lang=js&"
export * from "./RenderForm.vue?vue&type=script&lang=js&"
import style0 from "./RenderForm.vue?vue&type=style&index=0&id=5df66352&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5df66352",
  null
  
)

export default component.exports