import axios from 'axios'

export default {
  fetchCampaign (campaignId, skiphit, callback) {
    var headers = {
      'x-api-key': process.env.VUE_APP_GATEWAY_API_KEY
    }
    if (campaignId) {
      headers['state'] = 'public'
      headers['id'] = campaignId
    }
    if (skiphit) {
      headers['type'] = 'skiphit' // lets the landing page know that we need to skip the hit/view count
    }
    headers['ref'] = document.referrer
    if (document.URL.split('?', 2).length > 1) {
      headers['qs'] = document.URL.split('?', 2)[1]
    }
    axios.get(process.env.VUE_APP_GATEWAY_URL + '/public/campaign', { 'headers': headers })
      .then(r => r.data)
      .then(campaign => {
        callback(null, campaign)
      }).catch(error => {
        callback(error, null)
      })
  },
  fetchHeadline (accountSlug, totalToReturn, callback) {
    var headers = {
      'x-api-key': process.env.VUE_APP_GATEWAY_API_KEY,
      'state': 'public',
      'mode': 'headline',
      'type': totalToReturn
    }
    var nocache = '?r=' + Math.floor((Math.random() * 10000) + 1)
    if (accountSlug) headers['id'] = accountSlug
    axios.get(process.env.VUE_APP_GATEWAY_URL + '/public/home' + nocache, { 'headers': headers })
      .then(r => r.data)
      .then(feed => {
        callback(null, feed)
      }).catch(error => {
        callback(error, null)
      })
  },
  fetchPosts (accountSlug, include, exclude, headlinePostsToRemove, page, callback) {
    var headers = {
      'x-api-key': process.env.VUE_APP_GATEWAY_API_KEY,
      'state': 'public',
      'mode': 'posts',
      'type': headlinePostsToRemove
    }
    var nocache = '?r=' + Math.floor((Math.random() * 10000) + 1)
    if (accountSlug) headers['id'] = accountSlug
    if (page) headers['page'] = page
    var qs = []
    if (include) qs.push('in=' + include.toString())
    if (exclude) qs.push('ex=' + exclude.toString())
    if (qs.length > 0) headers.qs = qs.join('&')
    axios.get(process.env.VUE_APP_GATEWAY_URL + '/public/home' + nocache, { 'headers': headers })
      .then(r => r.data)
      .then(feed => {
        callback(null, feed)
      }).catch(error => {
        callback(error, null)
      })
  },
  fetchEvents (accountSlug, limit, callback) {
    var headers = {
      'x-api-key': process.env.VUE_APP_GATEWAY_API_KEY,
      'state': 'public',
      'mode': 'events'
    }
    var nocache = '?r=' + Math.floor((Math.random() * 10000) + 1)
    if (accountSlug) headers['id'] = accountSlug
    if (limit) headers['type'] = limit
    axios.get(process.env.VUE_APP_GATEWAY_URL + '/public/home' + nocache, { 'headers': headers })
      .then(r => r.data)
      .then(events => {
        callback(null, events)
      }).catch(error => {
        callback(error, null)
      })
  },
  fetchSales (accountSlug, limit, callback) {
    var headers = {
      'x-api-key': process.env.VUE_APP_GATEWAY_API_KEY,
      'state': 'public',
      'mode': 'sales'
    }
    var nocache = '?r=' + Math.floor((Math.random() * 10000) + 1)
    if (accountSlug) headers['id'] = accountSlug
    if (limit) headers['type'] = limit
    axios.get(process.env.VUE_APP_GATEWAY_URL + '/public/home' + nocache, { 'headers': headers })
      .then(r => r.data)
      .then(sales => {
        callback(null, sales)
      }).catch(error => {
        callback(error, null)
      })
  },
  postForm (account, campaignId, hitsId, form, labels, order, recaptchaToken, callback) {
    var headers = {
      'x-api-key': process.env.VUE_APP_GATEWAY_API_KEY,
      'state': 'public',
      'account_id': account.id,
      'id': campaignId
    }
    var payload = {}
    if (hitsId) payload.hitsId = hitsId
    if (form) payload.form = form
    if (labels) payload.labels = labels
    if (order) payload.order = order
    if (recaptchaToken) payload.recaptchaToken = recaptchaToken
    if (account.queues) payload.queues = account.queues
    axios.post(process.env.VUE_APP_GATEWAY_URL + '/public/form', JSON.stringify(payload), { 'headers': headers })
      .then(r => r.data)
      .then(response => {
        callback(null, response)
      }).catch(error => {
        callback(error, null)
      })
  },
  processPayment (mode, accountId, desc, order, finalTotal, taxTotal, token, callback) {
    var headers = {
      'x-api-key': process.env.VUE_APP_GATEWAY_API_KEY,
      'state': 'public',
      'account_id': accountId
    }
    var payload = {}
    if (mode) payload.mode = mode
    if (token) payload.token = token
    if (order) payload.order = order
    if (desc) payload.desc = desc
    if (finalTotal) payload.final = finalTotal
    if (taxTotal) payload.tax = taxTotal
    axios.post(process.env.VUE_APP_GATEWAY_URL + '/public/process', JSON.stringify(payload), { 'headers': headers })
      .then(r => r.data)
      .then(response => {
        callback(null, response)
      }).catch(error => {
        var errorText = 'There was an error while processing your card.'
        if (error.response) {
          if (error.response.status === 402) {
            errorText = 'soldout' // dont change this from soldout, used in checkout
          } else {
            // add on response from payment processor
            errorText = errorText + ' ' + error.response.data
          }
        }
        callback(errorText, null)
      })
  },
  updateOrder (account, campaignId, hitsId, words, order, text, callback) {
    var headers = {
      'x-api-key': process.env.VUE_APP_GATEWAY_API_KEY,
      'state': 'public',
      'account_id': account.id,
      'id': campaignId
    }
    var payload = {}
    if (hitsId) payload.hitsId = hitsId
    if (words) payload.words = words
    if (order) payload.order = order
    if (text) payload.text = text
    if (account.queues) payload.queues = account.queues
    axios.put(process.env.VUE_APP_GATEWAY_URL + '/public/order', JSON.stringify(payload), { 'headers': headers })
      .then(r => r.data)
      .then(response => {
        callback(null, response)
      }).catch(error => {
        callback(error, null)
      })
  },
  unpublish (accountId, campaignId, callback) {
    var headers = {
      'x-api-key': process.env.VUE_APP_GATEWAY_API_KEY,
      'state': 'public',
      'account_id': accountId,
      'id': campaignId
    }
    var payload = {}
    axios.post(process.env.VUE_APP_GATEWAY_URL + '/public/up', JSON.stringify(payload), { 'headers': headers })
      .then(r => r.data)
      .then(response => {
        callback(null, response)
      }).catch(error => {
        callback(error, null)
      })
  }
}
