import { version } from '../package.json'
// Polyfills for IE ;)
import 'core-js/stable'
import 'regenerator-runtime/runtime'
import 'isomorphic-fetch'
// Vue Packages
import Vue from 'vue'
import VeeValidate from 'vee-validate'
import VueScrollTo from 'vue-scrollto'
import App from './App.vue'
import router from './router'
// Animations
import 'vue2-animate/dist/vue2-animate.min.css'
// START Font Awesome 5 Support
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library, dom } from '@fortawesome/fontawesome-svg-core'
import {
  faAlignJustify, faAngleDown, faAngleLeft, faAngleRight, faArrowCircleDown, faArrowCircleUp, faArrowDown, faArrowLeft, faArrowRight,
  faArrowUp, faArrowsAlt, faAt, faBackspace, faBeer, faBrain, faBroadcastTower, faBullhorn, faCalendarAlt, faCalendarCheck,
  faCalendarPlus, faCaretSquareDown, faChartLine, faCheck, faCheckCircle, faCheckSquare, faChevronDown, faChevronUp,
  faCircle, faClock, faComment, faCopy, faCreditCard, faDesktop, faDollarSign, faDotCircle, faEdit, faEnvelope,
  faEnvelopeOpenText, faExclamationTriangle, faExclamationCircle, faFish, faFlag, faFutbol, faGift, faGlobe, faGlobeAmericas, faGolfBall, faHome,
  faICursor, faImage, faInfoCircle, faList, faLock, faMobile, faMobileAlt, faMoon, faMousePointer, faPen, faPencilRuler,
  faPhone, faPhoneSquare, faPlus, faPrint, faRetweet, faSave, faShareAlt, faSkull, faSmile, faStar, faStarOfLife, faStopwatch, faSyncAlt,
  faTachometerAlt, faTrashAlt, faTrophy, faUndo, faUser, faUserCircle, faUserFriends, faUsers, faUtensils, faWindowRestore
} from '@fortawesome/free-solid-svg-icons'
import {
  faFacebook, faFacebookSquare, faPagelines, faTwitter, faTwitterSquare, faInstagram, faGoogle, faSnapchatGhost,
  faCcVisa, faCcMastercard, faCcDiscover, faCcAmex, faCcJcb, faCcDinersClub
} from '@fortawesome/free-brands-svg-icons'
library.add(
  faAlignJustify, faAngleDown, faAngleLeft, faAngleRight, faArrowCircleDown, faArrowCircleUp, faArrowDown, faArrowLeft, faArrowRight,
  faArrowUp, faArrowsAlt, faAt, faBackspace, faBeer, faBrain, faBroadcastTower, faBullhorn, faCalendarAlt, faCalendarCheck,
  faCalendarPlus, faCaretSquareDown, faChartLine, faCheck, faCheckCircle, faCheckSquare, faChevronDown, faChevronUp,
  faCircle, faClock, faComment, faCopy, faCreditCard, faDesktop, faDollarSign, faDotCircle, faEdit, faEnvelope,
  faEnvelopeOpenText, faExclamationTriangle, faExclamationCircle, faFish, faFlag, faFutbol, faGift, faGlobe, faGlobeAmericas, faGolfBall, faHome,
  faICursor, faImage, faInfoCircle, faList, faLock, faMobile, faMobileAlt, faMoon, faMousePointer, faPen, faPencilRuler,
  faPhone, faPhoneSquare, faPlus, faPrint, faRetweet, faSave, faShareAlt, faSkull, faSmile, faStar, faStarOfLife, faStopwatch, faSyncAlt,
  faTachometerAlt, faTrashAlt, faTrophy, faUndo, faUser, faUserCircle, faUserFriends, faUsers, faUtensils, faWindowRestore,
  faFacebook, faFacebookSquare, faPagelines, faTwitter, faTwitterSquare, faInstagram, faGoogle, faSnapchatGhost,
  faCcVisa, faCcMastercard, faCcDiscover, faCcAmex, faCcJcb, faCcDinersClub
)
Vue.component('font-awesome-icon', FontAwesomeIcon)
dom.watch()
// END Font Awesome 5 Support

// Vue ScrollTo
Vue.use(VueScrollTo)

// Load VeeValidate and don't watch for events
Vue.use(VeeValidate, {
  inject: false
})

Vue.config.productionTip = false
console.info('%c  ___                       _           ___ _ _     _   \n' + ' / __|__ _ _ __  _ __  __ _(_)__ _ _ _ | _ (_) |___| |_ \n' + '| (__/ _` | \'  \\| \'_ \\/ _` | / _` | \' \\|  _/ | / _ \\  _|\n' + ' \\___\\__,_|_|_|_| .__/\\__,_|_\\__, |_||_|_| |_|_\\___/\\__|\n' + '                |_|  ✈️' + version + '  |___/                      \n\n' + '%c© 2023 1-2-1 Marketing Inc. All rights reserved.\n', 'font-family:monospace;color:#20b372;', 'font-family:monospace;')
if (process.env.NODE_ENV === 'development') console.warn(`%c⚠️ ${process.env.NODE_ENV} mode ⚠️`, 'font-family:monospace;color:#ffffff;background:#ff0000;font-size:20px;font-weight:bold;')

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
